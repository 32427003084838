import React from 'react';

import { pick } from '../../helpers/utils/object';
import { SIZES } from '../../types';

export const SIZE_OPTIONS = pick(SIZES, 'none', 'xxs', 'xs', 'sm', 'md', 'lg', 'xl');

export type SpacingType = keyof typeof SIZE_OPTIONS;

export type SpacingProps = {
  space: SpacingType | any;
  children?: React.ReactElement | React.ReactNode;
};

const spaceClasses: Record<SpacingType, string> = {
  none: '',
  xxs: 'py-1 sm:py-2 md:py-3 lg:py-4',
  xs: 'py-1 sm:py-2 md:py-3 lg:py-4',
  sm: 'py-2 sm:py-4 md:py-6 lg:py-8',
  md: 'py-4 sm:py-6 md:py-8 lg:py-10',
  lg: 'py-8 sm:py-10 md:py-12 lg:py-14',
  xl: 'py-10 sm:py-12 md:py-24 lg:py-28',
};

export const Spacing = ({ space = 'md', children }: SpacingProps) => {
  return <div className={spaceClasses[space]}>{children}</div>;
};

export const SpacingMemo = React.memo(Spacing);
