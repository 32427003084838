import cx from 'classnames';
import React from 'react';

import { backgroundClasses, textClasses } from '../colors';
import { AnimateInView } from '../components/animation/AnimateInView';
import { ButtonProps } from '../components/buttons/Button';
import { ButtonGroup } from '../components/buttons/ButtonGroup';
import { BackgroundProps } from '../components/module/Background';
import { DecorationProps } from '../components/module/Decoration';
import { Title } from '../components/module/Title';
import { Wrapper } from '../components/module/Wrapper';
import { RichText } from '../modules/RichText/RichText';
import { ColorType, HeadingSizeType } from '../types';
import { TitleHighlightType } from './HeroFormOptions';

export type HeroFormProps = {
  title?: string;
  titleTheme?: {
    highlightColor?: ColorType;
    highlightWord?: string;
    highlightType?: TitleHighlightType;
  };
  label?: string;
  intro?: React.ReactElement;
  children?: React.ReactNode;
  buttons?: ButtonProps[];
  form?: React.ReactElement;
  decorations?: DecorationProps[];
  headingSize?: HeadingSizeType;
} & Partial<BackgroundProps>;

const splitHero = true;

export const HeroForm = ({
  title,
  titleTheme,
  label,
  intro,
  theme = { background: 'black' },
  buttons,
  children,
  form,
  decorations,
  headingSize,
}: HeroFormProps) => {
  return (
    <Wrapper space="sm" theme={theme} decorations={decorations}>
      <div
        className={cx('relative max-w-75vww mx-auto', {
          ['grid xl:grid-cols-12 gap-12']: splitHero,
        })}
      >
        <div
          className={cx('min-h-[40vh] flex flex-col', {
            ['text-center justify-center max-w-title mx-auto']: true,
            ['xl:text-left xl:justify-start xl:max-w-full xl:mx-0']: splitHero,
            ['xl:col-span-7']: splitHero,
          })}
        >
          <div
            className={cx({
              ['sm:text-center lg:text-left']: splitHero,
            })}
          >
            {label && (
              <div>
                <span
                  className={cx(
                    'inline-block pt-[5px] pb-1 px-2 font-medium text-xs uppercase tracking-wider rounded-sm',
                    {
                      ['mb-2']: title,
                    },
                    backgroundClasses[theme?.labelBackground],
                    textClasses[theme?.label],
                  )}
                >
                  {label}
                </span>
              </div>
            )}
            <h1>
              {title && (
                <Title
                  size="md"
                  as={headingSize ?? 'span'}
                  className={textClasses[theme?.title]}
                >
                  {/*<HighlightTitle background={theme?.background} theme={titleTheme}>*/}
                  {title}
                  {/*</HighlightTitle>*/}
                </Title>
              )}
            </h1>
            {(intro || children) && (
              <div
                className={cx('mx-auto font-light', {
                  ['2xl:mx-0']: splitHero,
                })}
              >
                <RichText content={intro} theme={{ text: 'black' }} />
              </div>
            )}
            {Boolean(buttons?.length) && (
              <AnimateInView delay={0.15} className="mt-8 sm:mt-16">
                <ButtonGroup items={buttons} />
              </AnimateInView>
            )}
          </div>
        </div>
        {splitHero && (
          <div
            className={cx({
              ['w-full max-w-title mx-auto 2xl:mx-0 2xl:max-w-full xl:col-span-5 min-h-full flex flex-col justify-center relative']:
                true,
            })}
          >
            <div className="2xl:overflow-visible rounded-xl relative w-full">
              <div className={cx('')}>
                {form && (
                  <div className="lg:ml-10 sm:text-center lg:text-left">{form}</div>
                  // <Hubspot/>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </Wrapper>
  );
};

export const HeroFormMemo = React.memo(HeroForm);
