import cx from 'classnames';
import { motion, AnimatePresence } from 'framer-motion';
import React, { useCallback } from 'react';
import { useState } from 'react';

import { borderClasses, textClasses } from '../../colors';
import { wrap } from '../../helpers/utils/number';
import { Icon } from '../icons/Icon';
import { IconName } from '../icons/Icons';
import { BackgroundProps } from '../module/Background';
import { ColorType } from '../module/BackgroundOptions';
import { TestimonialProps, Testimonial } from './Testimonial';

export type SliderProps = {
  items: TestimonialProps[];
  icon?: IconName;
  mode?: TestimonialProps['mode'];
} & Partial<BackgroundProps>;

const animationVariants = {
  enter: (animationDirection: number) => {
    return {
      x: animationDirection > 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (animationDirection: number) => {
    return {
      zIndex: 0,
      x: animationDirection < 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
};

const SWIPE_CONFIDENCE_THRESHOLD = 1000;
const swipePower = (offset: number, velocity: number) => {
  return Math.abs(offset) * velocity;
};

export const Slider = ({
  theme = { background: 'white' },
  icon,
  mode = 'regular',
  items,
}: SliderProps) => {
  const [[page, animationDirection], setPage] = useState([0, 0]);
  const [isDragging, setIsDragging] = useState<boolean>(false);

  const slideIndex = wrap(0, items?.length, page);

  const paginate = useCallback(
    (newDirection: number) => setPage([page + newDirection, newDirection]),
    [page],
  );

  const onDragStart = () => {
    setIsDragging(true);
  };

  const onDragEnd = (e, { offset, velocity }) => {
    const swipe = swipePower(offset.x, velocity.x);
    setIsDragging(false);
    if (swipe < -SWIPE_CONFIDENCE_THRESHOLD) {
      paginate(1);
    } else if (swipe > SWIPE_CONFIDENCE_THRESHOLD) {
      paginate(-1);
    }
  };

  const onPageClick = (n: number) => {
    setPage([n, 0]);
  };

  return (
    <>
      {Boolean(items?.length) && (
        <div className="relative overflow-hidden">
          {icon && (
            <div
              className={cx(
                'mx-auto mb-6 md:mb-8 h-16 w-16',
                textClasses[theme?.icon],
              )}
            >
              <Icon name={icon} />
            </div>
          )}

          {items.length === 1 ? (
            <div
              className={cx({
                ['border']: mode === 'compact',
                [borderClasses[theme?.background]]: mode === 'compact',
              })}
            >
              <Testimonial mode={mode} {...items[0]} theme={theme} />
            </div>
          ) : (
            <>
              <div
                className={cx('w-full flex items-center', {
                  ['border']: mode === 'compact',
                  [borderClasses[theme?.background]]: mode === 'compact',
                })}
              >
                {items?.map((item) => (
                  <AnimatePresence
                    initial={false}
                    custom={animationDirection}
                    key={item.name}
                  >
                    <motion.div
                      whileDrag={{ opacity: 0.5 }}
                      className={cx('w-full flex-shrink-0 cursor-grab', {
                        ['cursor-grabbing']: isDragging,
                      })}
                      custom={animationDirection}
                      variants={animationVariants}
                      initial="enter"
                      animate="center"
                      exit="exit"
                      transition={{
                        x: { type: 'spring', stiffness: 300, damping: 30 },
                        opacity: { duration: 0.5 },
                      }}
                      drag="x"
                      dragConstraints={{ left: 0, right: 0 }}
                      dragElastic={1}
                      onDragStart={onDragStart}
                      onDragEnd={onDragEnd}
                    >
                      <Testimonial
                        mode={mode}
                        {...items[slideIndex]}
                        animationDirection={animationDirection}
                        theme={theme}
                      />
                    </motion.div>
                  </AnimatePresence>
                ))}
              </div>

              <ul className="mt-8 flex justify-center">
                {items.map((item, n) => (
                  <li key={n}>
                    <button
                      aria-label={`Slide {${n + 1}}`}
                      onClick={() => onPageClick(n)}
                      className={cx('p-2', {
                        ['opacity-30 hover:opacity-50']: slideIndex !== n,
                        ['opacity-60 hover:opacity-75']: slideIndex === n,
                      })}
                    >
                      <span
                        className={cx(
                          'rounded-full block w-2 h-2 transform transition-transform duration-100 bg-black',
                          {
                            ['scale-150']: slideIndex === n,
                          },
                        )}
                      >
                        <span className="sr-only">Slide {n + 1}</span>
                      </span>
                    </button>
                  </li>
                ))}
              </ul>
            </>
          )}
        </div>
      )}
    </>
  );
};

export const SliderMemo = React.memo(Slider);
