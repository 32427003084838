import { useEffect, useRef, useState } from 'react';
import React from 'react';
import Lottie from 'react-lottie-player/dist/LottiePlayerLight';

import { useInView } from '../../hooks/useInView';
import { LottieType } from '../../types';

// https://github.com/airbnb/lottie-web/blob/master/docs/json/animation.json
export type LottieJSONType = {
  w?: number;
  h?: number;
  fr?: number;
  ip?: number;
  op?: number;
};

export type LottiePlayerProps = {
  url?: string;
  play?: boolean;
  loop?: boolean;
  frame?: number;
  direction?: -1 | 1;
  onComplete?: (e, data: LottieJSONType) => void;
  onLoad?: (data: LottieJSONType) => void;
};

export const LottiePlayer = ({
  url,
  play,
  loop,
  direction,
  frame,
  onComplete = () => {},
  onLoad = () => {},
}: LottiePlayerProps) => {
  const [data, setData] = useState<LottieJSONType | null>(null);
  const [width, setWidth] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const lazyLoadInview = useInView({
    elementRef: wrapperRef,
    threshold: 0.01,
    once: true,
    rootMargin: '500px',
  });

  const inview = useInView({
    elementRef: wrapperRef,
    threshold: 0.01,
  });

  useEffect(() => {
    async function getJSON() {
      const json = await fetch(url);
      try {
        const obj = await json.json();
        setData(obj);
        onLoad(obj);
      } catch (err) {}
    }
    if (!url?.trim().length) return setData(null);
    if (lazyLoadInview) getJSON();
  }, [url, lazyLoadInview]);

  useEffect(() => {
    if (!data) return;
    const { w, h } = data;
    setWidth(w);
    setHeight(h);
  }, [data]);

  return (
    <div
      style={{ width, aspectRatio: `${width} / ${height}` }}
      className="max-w-full"
      ref={wrapperRef}
    >
      {data && inview && (
        <Lottie
          onComplete={(e) => onComplete(e, data)}
          onLoopComplete={(e) => onComplete(e, data)}
          animationData={data}
          loop={Boolean(loop)}
          play={play}
          direction={direction}
          goTo={frame}
        />
      )}
    </div>
  );
};

export const LottiePlayerMemo = React.memo(LottiePlayer);
