import cx from 'classnames';
import React from 'react';

import { DecorationProps } from '../../components/module/Decoration';
import { Wrapper } from '../../components/module/Wrapper';
import { ThemeType } from '../../types';

export type RichTextProps = {
  content: React.ReactElement;
  decorations?: DecorationProps[];
  theme?: ThemeType;
};

export const RichText = ({ content, theme, decorations }: RichTextProps) => {
  return (
    <Wrapper theme={theme} decorations={decorations}>
      <div
        className={cx(
          'mx-auto prose prose-md md:prose-lg break-words prose-current',
        )}
      >
        {content}
      </div>
    </Wrapper>
  );
};

export const RichTextMemo = React.memo(RichText);
