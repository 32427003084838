import cx from 'classnames';
import React from 'react';

import { backgroundClasses, textClasses } from '../../colors';
import { ThemeType } from '../../types';

export type BackgroundProps = {
  children: React.ReactElement | React.ReactNode;
  theme: ThemeType;
};

export const Background = ({
  children,
  theme = { title: 'black', text: 'black', background: 'white' },
}: BackgroundProps) => {
  return (
    <div
      className={cx(
        'relative',
        backgroundClasses[theme?.background],
        textClasses[theme?.text],
      )}
    >
      <div className={cx('relative z-1')}>{children}</div>
    </div>
  );
};

export const BackgroundMemo = React.memo(Background);
