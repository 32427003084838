import { SitemapItemType, SitemapType } from '../../queries/sitemap';
import { getItemForPath } from './getItemForPath';

export const getBreadCrumbForPath = (
  path: string,
  sitemap: SitemapType,
): SitemapItemType[] => {
  const pathParts = path.split('/');

  return path
    .split('/')
    .slice(1)
    .map((x, i) =>
      getItemForPath(`/${pathParts.slice(1, i + 2).join('/')}`, sitemap),
    )
    .filter(Boolean);
};
