import cx from 'classnames';
import React from 'react';

type FigCaptionProps = {
  caption: string;
};

export const FigCaption = ({ caption }: FigCaptionProps) => {
  if (!caption?.trim().length) return null;
  return (
    <figcaption className={cx('text-xs leading-relaxed prose-current')}>
      {caption}
    </figcaption>
  );
};

export const FigCaptionMemo = React.memo(FigCaption);
