import React from 'react';

import { Content, ContentProps } from '../../components/module/Content';
import { DecorationProps } from '../../components/module/Decoration';
import { Title } from '../../components/module/Title';
import { Wrapper } from '../../components/module/Wrapper';
import { HeadingSizeType, ThemeType } from '../../types';

export type CallToActionProps = {
  intro?: ContentProps['children'];
  theme?: ThemeType;
  decorations?: DecorationProps[];
  headingSize?: HeadingSizeType;
} & Partial<ContentProps>;

export const CallToAction = ({
  title,
  buttons,
  theme,
  intro,
  children,
  decorations,
  headingSize,
}: CallToActionProps) => {
  return (
    <Wrapper theme={theme} id={title} decorations={decorations} space="xl">
      <div className="text-center flex flex-col gap-8 items-center">
        {title && (
          <Title
            as={headingSize ?? 'h2'}
            size="xl"
            weight="extrabold"
            color={theme?.title}
            className="max-w-title"
          >
            {title}
          </Title>
        )}

        {(buttons || intro) && (
          <Content buttons={buttons} color={theme?.text} align="center">
            {intro}
          </Content>
        )}

        {children}
      </div>
    </Wrapper>
  );
};

export const CallToActionMemo = React.memo(CallToAction);
