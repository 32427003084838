import React from 'react';

import { Image } from '../../components/images/Image';
import { DecorationProps } from '../../components/module/Decoration';
import { Title } from '../../components/module/Title';
import { Wrapper } from '../../components/module/Wrapper';
import { ImageType, ThemeType } from '../../types';

export type LogoCloudProps = {
  theme?: ThemeType;
  decorations?: DecorationProps[];
  title?: string;
  items?: (ImageType & { _key?: string })[];
};

export const LogoCloud = ({ decorations, theme, title, items }: LogoCloudProps) => {
  return (
    <Wrapper theme={theme} id={title} decorations={decorations}>
      <div className="flex flex-col items-center text-center gap-10">
        {title && (
          <Title size="md" color={theme?.title}>
            {title}
          </Title>
        )}

        {Boolean(items?.filter(Boolean).length) && (
          <ul className="flex flex-wrap gap-3 justify-center">
            {items?.map((image) => (
              <li key={image?.src}>
                <div className="flex mb-6 w-full p-6 aspect-video items-center">
                  <div className="w-48">
                    <Image layout="responsive" {...image} />
                  </div>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
    </Wrapper>
  );
};

export const LogoCloudMemo = React.memo(LogoCloud);
