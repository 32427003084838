import { COLORS } from './colors';
import { ImageShapeType } from './components/images/ImageOptions';

export type ColorType = keyof typeof COLORS;

export const FONTS = {
  sans: 'Sans',
  heading: 'Heading',
  mono: 'Mono',
};

export type ThemeType = {
  background?: ColorType;
  [key: string]: ColorType;
};

export type HeadingSizeType = 'h2' | 'h3' | 'h4';

export type TextElement =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'span'
  | 'div'
  | 'p'
  | 'figcaption'
  | 'strong';

export type ImageType = {
  src: string;
  preventResize?: boolean;
  width?: number;
  height?: number;
  alt?: string;
  caption?: string;
  shape?: ImageShapeType;
  crop?: {
    _type: 'sanity.imageCrop';
    bottom: number;
    left: number;
    right: number;
    top: number;
  };
  hotspot?: {
    _type: 'sanity.imageHotspot';
    height: number;
    width: number;
    x: number;
    y: number;
  };
};

export type LottieType = {
  url?: string;
  autoPlay?: boolean;
  hoverPlay?: boolean;
  loop?: boolean;
};

export const VIDEO_PROVIDERS = {
  youtube: 'Youtube',
  vimeo: 'Vimeo',
  cloudinary: 'Cloudinary',
  mux: 'Mux',
  sanity: 'Sanity',
  static: 'Static',
};

export type VideoProviderType = keyof typeof VIDEO_PROVIDERS;

export type VideoType = {
  loop?: boolean;
  caption?: string;
  autoPlay?: boolean;
  provider?: VideoProviderType;
  videoId?: string;
  src?: string;
  frameless?: boolean;
  poster?: ImageType;
};

export const SIZES = {
  none: 'None',
  xxs: 'Extra Extra Small',
  xs: 'Extra Small',
  sm: 'Small',
  md: 'Medium',
  lg: 'Large',
  xl: 'Extra Large',
  xxl: 'Extra Extra Large',
};

export type SizeType = keyof typeof SIZES;
export type SizesType = { [key in keyof typeof SIZES]: string };

export const FONT_WEIGHTS = {
  thin: 'Thin',
  extralight: 'Extralight',
  light: 'Light',
  normal: 'Normal',
  regular: 'Regular',
  medium: 'Medium',
  semibold: 'Semibold',
  bold: 'Bold',
  extrabold: 'Extrabold',
  black: 'Black',
};

export type FontWeightType = keyof typeof FONT_WEIGHTS;
export type FontWeightsType = { [key in keyof typeof FONT_WEIGHTS]: string };

export const ALIGNMENTS = {
  left: 'Left',
  center: 'Center',
  right: 'Right',
  auto: 'Auto',
  top: 'Top',
  middle: 'Middle',
  bottom: 'Bottom',
  insideLeft: 'Inside left',
  insideRight: 'Inside right',
};

export type AlignmentType = keyof typeof ALIGNMENTS;
export type AlignmentsType = { [key in keyof typeof ALIGNMENTS]: string };

export const RATIOS = {
  '1/1': 'Square',
  '16/9': '16/9',
  '2/1': 'Flat',
  '13/8': '13/8',
  '4/3': '4/3',
};

export type RatioType = keyof typeof RATIOS;
export type RatiosType = { [key in keyof typeof RATIOS]: string };

export const STATIC_FORMS = {
  hubspot: 'hubspot',
  'newsletter-sign-up': 'Newsletter sign up',
  contact: 'Contact',
};

export type StaticFormType = keyof typeof STATIC_FORMS;
export type StaticFormsType = { [key in keyof typeof STATIC_FORMS]: string };

export const STATIC_FORM_OPTIONS = {
  slackWebhookURL: 'Slack webhook URL',
  HUBSPOT_REGION: 'Hubspot region',
  HUBSPOT_PORTAL_ID: 'Hubspot portal ID',
  HUBSPOT_FORM_ID: 'Hubspot form ID',
  TITLE: 'Form Title',
  SUBTITLE: 'Form Subtitle',
  SUBMIT_TEXT: 'Submit button text',
  isShowSuccessMessage: 'Show only success message',
};

export type StaticFormOptionType = keyof typeof STATIC_FORM_OPTIONS;
export type StaticFormOptionsType = {
  [key in keyof typeof STATIC_FORM_OPTIONS]?: string;
};

export type SuccessOrErrorMessage = { success: string } | { error: string };

export const DECORATIONS = {
  square: 'square',
  waves: 'waves',
  dots: 'dots',
};

export const SHAPES = {
  square: 'Square',
  circle: 'Circle',
  rounded: 'Rounded',
};

export type ShapesType = keyof typeof SHAPES;
export type ShapeType = { [key in keyof typeof SHAPES]: string };
