import tinycolor from 'tinycolor2';

import { pick } from './helpers/utils/object';
import { ColorType } from './types';

export const COLORS = {
  white: '#fff',
  black: '#000',

  'navy-50': '#D9E5F7',
  'navy-100': '#CAD3F3',
  'navy-200': '#8A94B2',
  'navy-300': '#2A68C6',
  'navy-400': '#1A407A',
  'navy-500': '#172A46',
  'navy-600': '#0A192F',
  'navy-700': '#081426',
  'navy-800': '#06101D',
  'navy-900': '#040911',

  'gray-50': '#f9fafb',
  'gray-100': '#f3f4f6',
  'gray-200': '#e5e7eb',
  'gray-300': '#d1d5db',
  'gray-400': '#9ca3af',
  'gray-500': '#6b7280',
  'gray-600': '#4b5563',
  'gray-700': '#374151',
  'gray-800': '#1f2937',
  'gray-900': '#111827',

  'purple-50': '#F0F1FF',
  'purple-100': '#E0E3FF',
  'purple-200': '#BDC2FF',
  'purple-300': '#9EA6FF',
  'purple-400': '#808AFF',
  'purple-500': '#5F6CFF',
  'purple-600': '#3D49D6',
  'purple-700': '#1D28A3',
  'purple-800': '#000862',
  'purple-900': '#000539',

  'green-50': '#F3FCFC',
  'green-100': '#E3F7F7',
  'green-200': '#C7F0EF',
  'green-300': '#ABE8E7',
  'green-400': '#8FE0DF',
  'green-500': '#74D9D7',
  'green-600': '#3FCAC8',
  'green-700': '#2B9C9A',
  'green-800': '#1C6867',
  'green-900': '#0E3433',

  'yellow-50': '#FFFBEB',
  'yellow-100': '#FFF7D1',
  'yellow-200': '#FFEEA3',
  'yellow-300': '#FFE675',
  'yellow-400': '#FFDE4D',
  'yellow-500': '#FFD51D',
  'yellow-600': '#E6BB00',
  'yellow-700': '#A88900',
  'yellow-800': '#705C00',
  'yellow-900': '#473A00',

  'pink-50': '#FEF1F7',
  'pink-100': '#FDDEED',
  'pink-200': '#FBBCDA',
  'pink-300': '#F89BC8',
  'pink-400': '#F679B6',
  'pink-500': '#F459A5',
  'pink-600': '#F01981',
  'pink-700': '#BB0C61',
  'pink-800': '#7C0840',
  'pink-900': '#3E0420',
};

export const ALL_COLORS = Object.keys(COLORS) as ColorType[];

export const STORYBOOK_COLORS_SUBSET = COLORS;
// use this if you have a lot of colors and don't to use all of them in storybook
// export const STORYBOOK_COLORS_SUBSET = pick(COLORS, 'white', 'black');

export const backgroundClasses: Record<ColorType, string> = Object.entries(
  COLORS,
).reduce<Record<ColorType, string>>(
  (acc, [key, value]) => ({ ...acc, [key]: `bg-${key}` }),
  {} as Record<ColorType, string>,
);

export const textClasses: Record<ColorType, string> = Object.entries(COLORS).reduce<
  Record<ColorType, string>
>(
  (acc, [key, value]) => ({ ...acc, [key]: `text-${key}` }),
  {} as Record<ColorType, string>,
);

export const borderClasses: Record<ColorType, string> = Object.entries(
  COLORS,
).reduce<Record<ColorType, string>>(
  (acc, [key, value]) => ({ ...acc, [key]: `border-${key}` }),
  {} as Record<ColorType, string>,
);

export const proseClasses: Record<ColorType, string> = Object.entries(COLORS).reduce<
  Record<ColorType, string>
>(
  (acc, [key, value]) => ({
    ...acc,
    [key]: `prose-${
      tinycolor(COLORS[key]).isDark() ? 'prose-invert' : 'prose-coal'
    }`,
  }),
  {} as Record<ColorType, string>,
);
