import React from 'react';

import { ConfigType } from '../queries/config';
import { SitemapType } from '../queries/sitemap';

export const PageContext = React.createContext({
  preview: false,
  sitemap: [] as SitemapType,
  config: {} as ConfigType,
});
