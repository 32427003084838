import React, { useState, useEffect } from 'react';

import { BackgroundProps } from '../../components/module/Background';
import { Title } from '../../components/module/Title';
import { Wrapper } from '../../components/module/Wrapper';
import { ThemeType } from '../../types';
import { Categories, CategoryProps, FilterSelect } from '../BlogFeed/BlogFeed';
import { CardGrid } from '../CardGrid/CardGrid';
import { IconCardProps } from '../CardGrid/IconCard';

export type WorkGridProps = {
  title: string;
  items: IconCardProps & { categories: CategoryProps[] }[];
  displayFilter?: boolean;
  theme?: ThemeType;
  slider?: boolean;
} & Partial<BackgroundProps>;

export const WorkGrid = ({
  title,
  theme,
  items,
  displayFilter = false,
  slider = false,
}: WorkGridProps) => {
  if (!items || items?.length < 1) {
    return null;
  }

  const [category, setCategory] = useState<CategoryProps | null>(null);
  const [filteredItems, setFilteredItems] = useState<
    IconCardProps & { categories: CategoryProps[] }[]
  >(items);

  useEffect(() => {
    if (category) {
      setFilteredItems(
        items.filter((s) =>
          s.categories?.find((s: CategoryProps) => s._id === category._id),
        ),
      );
    } else {
      setFilteredItems(items);
    }
  }, [category]);

  const categories = items.reduce((categories: CategoryProps[], item) => {
    if (!item.categories) {
      return categories;
    }

    return categories.concat(
      item.categories.filter(
        (category) => !categories.find((s: CategoryProps) => s._id === category._id),
      ),
    );
  }, []);

  return (
    <Wrapper theme={theme}>
      {title && (
        <Title color={theme?.title} size="md">
          {title}
        </Title>
      )}

      {displayFilter ? (
        <div className="flex justify-between flex-row items-center">
          <Categories {...{ categories }} />

          <FilterSelect
            onChange={(category: string) =>
              setCategory(categories.find((s) => s.title === category))
            }
            categories={categories}
          />
        </div>
      ) : null}
      <CardGrid
        slider={slider}
        theme={theme}
        items={filteredItems.map(({ categories, ...item }) => item)}
      />
    </Wrapper>
  );
};

export const WorkGridMemo = React.memo(WorkGrid);
