import cx from 'classnames';
import Link from 'next/link';
import React from 'react';

import { backgroundClasses, textClasses } from '../../colors';
import { AnimateInView } from '../../components/animation/AnimateInView';
import { ButtonProps } from '../../components/buttons/Button';
import { ButtonGroup } from '../../components/buttons/ButtonGroup';
import { Image } from '../../components/images/Image';
import { LottieHoverPlayerMemo as LottieHoverPlayer } from '../../components/images/LottieHoverPlayer';
import { useHover } from '../../hooks/useHover';
import { ImageType, LottieType, RatioType } from '../../types';
import { CardGridCardProps } from './CardGrid';
import { AlignType } from './CardGridOptions';

export type IconCardProps = {
  type?: 'card.icon';
  title?: string;
  label?: string;
  text?: string;
  image?: ImageType;
  lottie?: LottieType;
  href?: string;
  buttons?: ButtonProps[];
  imageRatio?: RatioType;
  imageFullBleed?: Boolean;
  clickable?: Boolean;
  align?: AlignType;
} & CardGridCardProps;

const ratioClasses: Record<RatioType, string> = {
  '1/1': 'aspect-[1/1]',
  '16/9': 'aspect-[16/9]',
  '2/1': 'aspect-[2/1]',
  '13/8': 'aspect-[13/8]',
  '4/3': 'aspect-[4/3]',
};

export const IconCard = ({
  title,
  label,
  text,
  image,
  lottie,
  theme,
  rounded,
  buttons,
  imageFullBleed = false,
  imageRatio = '16/9',
  align = 'left',
  clickable = false,
  href,
}: IconCardProps) => {
  const [hoverRef, isHovered] = useHover<HTMLDivElement>();

  const cardClickable =
    (clickable && buttons?.length === 1 && buttons?.[0]?.href) || href;

  return (
    <div ref={hoverRef} className={cx('h-full relative group', {})}>
      {cardClickable && (
        <Link href={href ?? buttons?.[0]?.href}>
          <a className="absolute inset-0 z-10 opacity-0 transition-opacity hover:opacity-100">
            <span className="sr-only">{buttons?.[0].label}</span>
          </a>
        </Link>
      )}

      <AnimateInView
        x={-10}
        y={0}
        className={cx(
          'flex flex-col relative gap-4 overflow-hidden h-full border border-black border-opacity-5',
          backgroundClasses[theme?.background],
          {
            ['rounded-xl']: rounded,
            ['text-left items-start']: align === 'left',
            ['text-center items-center']: align === 'center',
            ['text-right items-end']: align === 'right',
          },
        )}
      >
        {image && (
          <AnimateInView
            className={cx(
              'relative overflow-hidden',
              ratioClasses[imageRatio || '16/9'],
              {
                'w-full': imageFullBleed,
                'w-48 mx-8 mt-8': !imageFullBleed,
              },
            )}
          >
            <div
              className={cx('absolute inset-0', {
                ['transition-transform ease-out-cubic group-hover:scale-125 duration-500 origin-center']:
                  cardClickable,
              })}
            >
              <Image {...image} alt="Icon card image" layout="fill" />
            </div>
          </AnimateInView>
        )}
        {lottie?.url?.trim().length && (
          <AnimateInView
            className={cx({
              'w-full': imageFullBleed,
              'w-48 mx-8 mt-8': !imageFullBleed,
            })}
          >
            <LottieHoverPlayer {...lottie} isHovered={isHovered} reverse />
          </AnimateInView>
        )}
        {(label || title || text || Boolean(buttons?.length)) && (
          <div
            className={cx(
              'flex gap-4 flex-col z-1 relative p-8',
              textClasses[theme?.text],
              {
                ['pt-4']: image,
              },
            )}
          >
            {label && (
              <AnimateInView delay={0.1} x={-5} y={0}>
                <span className="text-sm opacity-80 uppercase text-uppercase leading-tight font-mono">
                  {label}
                </span>
              </AnimateInView>
            )}
            {title && (
              <AnimateInView delay={0.1} x={5}>
                <h3
                  className={cx(
                    'text-2xl font-medium leading-tight font-heading tracking-tight',
                    textClasses[theme?.title],
                    {
                      ['group-hover:underline']: cardClickable,
                    },
                  )}
                >
                  {title}
                </h3>
              </AnimateInView>
            )}

            {text && (
              <AnimateInView delay={0.2} x={-5}>
                <div
                  className={cx(
                    'text-xl underline-links leading-9 break-words',
                    textClasses[theme?.text],
                  )}
                >
                  {text}
                </div>
              </AnimateInView>
            )}

            {buttons && (
              <AnimateInView delay={0.25} x={-10} className="mt-4">
                <ButtonGroup items={buttons} stretch={false} direction="vertical" />
              </AnimateInView>
            )}
          </div>
        )}
      </AnimateInView>
    </div>
  );
};
