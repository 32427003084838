// https://github.com/stbaer/smooth-path/blob/master/index.js
// http://www.idav.ucdavis.edu/education/CAGDNotes/Chaikins-Algorithm/Chaikins-Algorithm.html
import { randomBetween } from './number';

export const smooth = (path) => {
  var n = path.length,
    result = [],
    i = 0,
    resultIndex = 0,
    p0x,
    p0y,
    p1x,
    p1y;

  for (i; i < n - 2; i += 2) {
    p0x = path[i];
    p0y = path[i + 1];
    p1x = path[i + 2];
    p1y = path[i + 3];

    result[resultIndex++] = 0.75 * p0x + 0.25 * p1x;
    result[resultIndex++] = 0.75 * p0y + 0.25 * p1y;
    result[resultIndex++] = 0.25 * p0x + 0.75 * p1x;
    result[resultIndex++] = 0.25 * p0y + 0.75 * p1y;
  }

  return result;
};

// Smooth an array of points and make it an svg path
//  https://codepen.io/osublake/pen/BowJed
export function smoothPath(data: number[], k: number = null) {
  if (k == null) k = 1;

  var size = data.length;
  var last = size - 4;

  var path = 'M' + [data[0], data[1]];

  for (var i = 0; i < size - 2; i += 2) {
    var x0 = i ? data[i - 2] : data[0];
    var y0 = i ? data[i - 1] : data[1];

    var x1 = data[i + 0];
    var y1 = data[i + 1];

    var x2 = data[i + 2];
    var y2 = data[i + 3];

    var x3 = i !== last ? data[i + 4] : x2;
    var y3 = i !== last ? data[i + 5] : y2;

    var cp1x = x1 + ((x2 - x0) / 6) * k;
    var cp1y = y1 + ((y2 - y0) / 6) * k;

    var cp2x = x2 - ((x3 - x1) / 6) * k;
    var cp2y = y2 - ((y3 - y1) / 6) * k;

    path += 'C' + [cp1x, cp1y, cp2x, cp2y, x2, y2];
  }

  return path;
}

export const scramble = (data: number[]) =>
  data.map((x, n) =>
    n > data.length - 8 || n % 2 === 0 ? x : x * randomBetween(0.8, 1.2),
  );

// https://github.com/georgedoescode/generative-utils/blob/master/src/pointsInPath.js
export const pointsInPath = (path, numPoints = 10) => {
  const pathLength = path.getTotalLength();
  const step = pathLength / numPoints;
  const points = [];

  for (let i = 0; i < pathLength; i += step) {
    points.push(path.getPointAtLength(i));
  }

  return points;
};
