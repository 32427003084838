import { useEffect, useRef, useState } from 'react';
import React from 'react';

import { LottieType } from '../../types';
import { LottieJSONType, LottiePlayer, LottiePlayerProps } from './LottiePlayer';

export type LottieHoverPlayerProps = {
  isHovered?: boolean;
  reverse?: boolean;
} & LottiePlayerProps &
  LottieType;

export const LottieHoverPlayer = ({
  autoPlay,
  loop,
  isHovered,
  hoverPlay,
  reverse,
  ...rest
}: LottieHoverPlayerProps) => {
  const [direction, setDirection] = useState<-1 | 1>(1);
  const [play, setPlay] = useState<boolean>(autoPlay || false);
  const [frame, setFrame] = useState<number>(0);
  const [_loop, setLoop] = useState<boolean>(loop || false);
  const [data, setData] = useState<LottieJSONType | null>(null);

  const completeCounter = useRef<number>(0);

  useEffect(() => {
    if (typeof isHovered === 'undefined') return;
    if (!hoverPlay) return;

    // allow initial play to complete and have no hover effect
    if (autoPlay && completeCounter.current === 0) return;

    // unpause
    setPlay(true);

    // set the direction based on hover state
    if (reverse) setDirection(isHovered ? 1 : -1);
  }, [isHovered, hoverPlay, reverse]);

  const onAnimationComplete = (e, data: LottieJSONType) => {
    completeCounter.current += 1;

    // allow initial play
    if (autoPlay && completeCounter.current === 0) return;

    // after initial play go to frame 1
    if (autoPlay && completeCounter.current === 1) {
      setFrame(1);
      setPlay(false);
      return;
    }

    // on hover set correct frame and pause
    if (hoverPlay) {
      setFrame(isHovered ? Math.floor(data.op) - 1 : 1);
      setPlay(false);
    }
  };

  return (
    <>
      <LottiePlayer
        {...rest}
        play={play}
        direction={direction}
        loop={_loop}
        frame={frame}
        onComplete={onAnimationComplete}
        onLoad={setData}
      />
    </>
  );
};

export const LottieHoverPlayerMemo = React.memo(LottieHoverPlayer);
