import cx from 'classnames';
import React from 'react';

import { slugify } from '../../helpers/utils/string';
import { ThemeType } from '../../types';
import { Background, BackgroundProps } from './Background';
import { DecorationProps } from './Decoration';
import { Decorations } from './Decorations';
import { Spacing, SpacingProps, SpacingType } from './Spacing';
import { Width, WidthProps } from './Width';

export type WrapperProps = {
  id?: string;
  children: React.ReactElement | React.ReactNode;
  decorations?: DecorationProps[];
  width?: WidthProps['width'];
  theme?: ThemeType;
  space?: SpacingType;
};

export const Wrapper = ({
  children,
  theme,
  id,
  decorations,
  width = 'inner',
  space,
}: WrapperProps) => {
  const WidthEl = width ? Width : React.Fragment;
  return (
    <div id={id ? slugify(id) : null}>
      <Background theme={theme}>
        <WidthEl>
          {decorations && <Decorations decorations={decorations} />}
          <div className={cx('relative z-1')}>
            <Spacing space={theme?.space ?? space}>{children}</Spacing>
          </div>
        </WidthEl>
      </Background>
    </div>
  );
};

export const WrapperMemo = React.memo(Wrapper);
