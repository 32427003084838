import React from 'react';

import { BackgroundProps } from '../../components/module/Background';
import { Title } from '../../components/module/Title';
import { Wrapper } from '../../components/module/Wrapper';
import { ThemeType } from '../../types';
import { CategoryProps } from '../BlogFeed/BlogFeed';
import { CardGrid } from '../CardGrid/CardGrid';
import { IconCardProps } from '../CardGrid/IconCard';

export type BlogGridProps = {
  title: string;
  items: Omit<IconCardProps, 'text'> & { categories: CategoryProps[] }[];
  theme?: ThemeType;
  slider?: boolean;
} & Partial<BackgroundProps>;

export const BlogGrid = ({ title, theme, items, slider = false }: BlogGridProps) => {
  if (!items || items?.length < 1) {
    return null;
  }

  return (
    <Wrapper theme={theme}>
      {Boolean(title) && <Title color={theme?.title}>{title}</Title>}

      <CardGrid
        slider={slider}
        theme={theme}
        items={items.map(({ categories, ...item }) => item)}
      />
    </Wrapper>
  );
};

export const BlogGridMemo = React.memo(BlogGrid);
