import { motion } from 'framer-motion';
import React, {useEffect, useRef, useState} from 'react';

import { StaticFormState } from '../layout/ModuleBuilder/StaticFormBuilder';
import { customAlphabet } from 'nanoid';
import NextLink from 'next/link';
import Script from 'next/script';

import { Spinner } from '../components/loaders/Spinner';
import { Title } from '../components/module/Title';
import { Text } from '../components/module/Text';
import { StaticFormOptionsType } from '../types';
import cx from "classnames";

export type HubspotValues = {
  name?: string;
  email?: string;
};

export type HubspotProps = {
  success?: React.ReactNode | React.ReactElement;
  error?: React.ReactNode | React.ReactElement;
  state?: StaticFormState;
  onSubmit?: (values: HubspotValues) => void;
  result?: string;
  options?: StaticFormOptionsType;
};

export const Hubspot = ({
                          success,
                          error,
                          onSubmit,
                          result,
                          state,
                           options,
}
        : HubspotProps) => {
  const wrapperRef = useRef(null);
  const [status, setStatus] = useState<'loading' | 'complete' | 'error'>('loading');
  const [id, setId] = useState<string>(null);

  useEffect(() => {
    setId(customAlphabet('abcdefghijklmnopqrstuvwxyz', 8)());
  }, []);

  useEffect(() => {
    setStatus('loading');
    function load() {
      if (!id || !(window as any).hbspt) {
        return setTimeout(load, 50);
      }

      if (wrapperRef.current.firstChild) {
        wrapperRef.current.removeChild(wrapperRef.current.firstChild);
      }

      (window as any).hbspt.forms.create({
        region: options.HUBSPOT_REGION,
        portalId: options.HUBSPOT_PORTAL_ID,
        formId: options.HUBSPOT_FORM_ID,
        target: `#hubspot-${id}`,
        cssClass: '',
        errorMessageClass: 'text-red-500 text-sm',
          onFormSubmit: function($form) {
              // YOUR SCRIPT HERE
          }
      });

      setStatus('complete');
    }
    load();
  }, [id, options]);

  return options?.isShowSuccessMessage === 'true' ? (
      <div className="hubspot inline-block w-550 max-w-full shadow-xl bg-white border-black border-opacity-5 px-10 pt-6 text-indigo-500 border">
        <div className="border-b border-gray-300 pb-5 mb-6 text-center">
          <Title size="sm">{options.TITLE}</Title>
          <Text size="md" align="center" >{options.SUBTITLE}</Text>
        </div>
        <motion.div
            className="prose lg:prose-xl text-center"
            animate={{ opacity: 1 }}
            initial={{ opacity: 0 }}
            transition={{ ease: 'circOut', delay: 0.25 }}
        >
          {success}
        </motion.div>
        <Text size="sm">
          By submitting this form, you confirm that you accept mawla.agency’s{' '}
          <NextLink href="/legal/privacy-policy">
            <a className="text-indigo-500 underline">Privacy Policy</a>
          </NextLink>
          .
        </Text>
      </div>
  ) : (
      <div>
        <div className="hubspot inline-block w-550 max-w-full shadow-xl bg-white border-black border-opacity-5 px-10 pt-6 text-indigo-500 border">
          <div className="border-b border-gray-300 pb-5 mb-6 text-center">
              <Title size="sm">{options.TITLE}</Title>
              <Text size="md" align="center">{options.SUBTITLE}</Text>
          </div>
          <div ref={wrapperRef} id={`hubspot-${id}`} />
          {status === 'loading' && (
              <div className="flex gap-2 justify-center">
                <div className="w-5 h-5">
                  <Spinner />
                </div>
              </div>
          )}
          {status === 'complete' && (
              <Text size="sm" className={cx("pb-5")}>
                By submitting this form, you confirm that you accept mawla.agency’s{' '}
                <NextLink href="/legal/privacy-policy">
                  <a className="text-indigo-500 underline">Privacy Policy</a>
                </NextLink>
                .
              </Text>
          )}
        </div>

        <Script
            type="text/javascript"
            src="//js.hsforms.net/forms/v2.js" //might need eu here
            strategy="lazyOnload"
        />
        {/* {state !== 'success' && ( */}

        {/* )} */}
      </div>
  );
};

export const HubspotMemo = React.memo(Hubspot);

