import cx from 'classnames';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import React from 'react';
import tinycolor from 'tinycolor2';

import { COLORS } from '../../colors';
import { randomBetween } from '../../helpers/utils/number';
import { scramble, smoothPath } from '../../helpers/utils/path';
import { HorizontalAlignType, VerticalAlignType } from './DecorationOptions';

const horizontalRotateClasses: Record<HorizontalAlignType, string> = {
  left: '',
  right: '-scale-x-100',
  center: '',
  insideLeft: '',
  insideRight: '',
};

const verticalRotateClasses: Record<VerticalAlignType, string> = {
  top: '-scale-y-100 -translate-y-px',
  middle: '',
  bottom: 'translate-y-px',
};

export const DecorationWaves = ({
  verticalAlign,
  horizontalAlign,
  color,
  points,
}) => {
  const router = useRouter();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);
  const [path, setPath] = useState<string>(null);
  const [polypoints, setPolypoints] = useState<string>(null);
  const [pathPoints, setPathPoints] = useState<number[]>(null);

  const NUM_WAVES = points + 4;
  const DEBUG = false;

  useEffect(() => {
    function onResize() {
      if (!wrapperRef?.current) return;
      const rect = wrapperRef.current?.getBoundingClientRect();

      const height = rect.height;
      const width = rect.width;

      setWidth(width);
      setHeight(height);

      const xStep = width / NUM_WAVES;

      const pathPoints = [
        0,
        height / 5,

        // first wave
        xStep * 1,
        10,

        xStep * 2,
        (height / 2) * randomBetween(0.9, 1.1),
        // second wave
        xStep * 3,
        (height / 3) * randomBetween(0.9, 1.1),

        xStep * 4,
        (height / 1.1) * randomBetween(0.9, 1.1),

        // third wave
        xStep * 5,
        (height / 1.5) * randomBetween(0.9, 1.1),

        xStep * 6,
        (height / 1.1) * randomBetween(0.9, 1.1),

        xStep * 7,
        height,

        width,
        height + 15,

        0,
        height,

        0,
        0,
      ];

      if (points === 1) pathPoints.splice(10, 6);
      setPolypoints(pathPoints.join(','));
      setPath(smoothPath(pathPoints));
      setPathPoints(pathPoints);
    }

    onResize();
    router.events.on('routeChangeComplete', onResize);
    window.addEventListener('resize', onResize);
    () => {
      window.removeEventListener('resize', onResize);
      router.events.off('routeChangeComplete', onResize);
    };
  }, [points]);

  return (
    <div
      className={cx(
        'w-full  max-h-[15vw]',
        horizontalRotateClasses[horizontalAlign],
        verticalRotateClasses[verticalAlign],
      )}
      ref={wrapperRef}
    >
      <svg viewBox={`0 0 ${width} ${height}`} fill={COLORS[color]}>
        <path d={path} />

        {pathPoints &&
          [...Array(10)].map((x, n) => {
            let lineColor = tinycolor(COLORS[color]);
            const yesno = Boolean(Math.round(Math.random()));
            if (yesno)
              lineColor.darken(randomBetween(0, lineColor.isDark() ? 50 : 40));
            if (!yesno)
              lineColor.lighten(randomBetween(0, lineColor.isDark() ? 25 : 100));

            lineColor = lineColor.setAlpha(randomBetween(0.1, 0.95));

            return (
              <g key={n}>
                <defs>
                  <filter id={`f${x}`} x="0" y="0">
                    <feGaussianBlur
                      in="SourceGraphic"
                      stdDeviation={randomBetween(0, 0.5)}
                    />
                  </filter>
                </defs>
                <path
                  d={smoothPath(scramble(pathPoints))}
                  stroke={lineColor}
                  opacity={randomBetween(0.1, 0.5)}
                  fill="none"
                  filter={`url(#f${x})`}
                />
              </g>
            );
          })}
        {DEBUG && <polyline points={polypoints} stroke="#888" fill="none" />}
      </svg>
    </div>
  );
};

export default React.memo(DecorationWaves);
