import cx from 'classnames';
import React from 'react';

import { textClasses } from '../../colors';
import { pick } from '../../helpers/utils/object';
import { SIZES, TextElement, FONT_WEIGHTS } from '../../types';
import { ColorType } from '../../types';

export const SIZE_OPTIONS = pick(SIZES, 'xxs', 'xs', 'sm', 'md', 'lg', 'xl', 'xxl');
export type SizeType = keyof typeof SIZE_OPTIONS;

export const WEIGHT_OPTIONS = pick(
  FONT_WEIGHTS,
  'regular',
  'medium',
  'semibold',
  'extrabold',
);
export type WeightType = keyof typeof WEIGHT_OPTIONS;

export type TitleProps = {
  children: React.ReactElement | React.ReactNode;
  as?: TextElement;
  size?: SizeType;
  weight?: WeightType;
  className?: string;
  color?: ColorType;
};

const sizeClasses: Record<SizeType, string> = {
  xxs: 'text-lg',
  xs: 'text-xl',
  sm: 'text-2xl md:text-2xp lg:text-3xl',
  md: 'text-2xl md:text-3xl lg:text-4xl',
  lg: 'text-2xl md:text-3xl lg:text-4xl',
  xl: 'text-2xl md:text-4xl',
  xxl: 'text-2xl md:text-3xl lg:text-4xl',
};

const weightClasses: Record<WeightType, string> = {
  regular: 'font-regular',
  medium: 'font-medium',
  extrabold: 'font-extrabold',
  semibold: 'font-semibold',
};

export const Title = ({
  children,
  as = 'h2',
  size = 'sm',
  weight = 'extrabold',
  className,
  color,
}: TitleProps) => {
  const Element = as;
  const titleWords = children?.toString()?.trim().split(' ');

  return (
    <Element
      className={cx(
        'break-words inline-block',
        'font-heading',
        'leading-[1.1]',
        textClasses[color],
        sizeClasses[size],
        weightClasses[weight],
        className,
      )}
    >
      {typeof children === 'string' ? titleWords.join(' ') : children}
    </Element>
  );
};

export const TitleMemo = React.memo(Title);
