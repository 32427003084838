import React from 'react';
import * as ReactDOMServer from 'react-dom/server';

import {
  AccordionItemType,
  Accordion as AccordionComponent,
} from '../../components/Accordion/Accordion';
import { BackgroundProps } from '../../components/module/Background';
import { DecorationProps } from '../../components/module/Decoration';
import { Text } from '../../components/module/Text';
import { Title } from '../../components/module/Title';
import { Wrapper } from '../../components/module/Wrapper';
import { HeadingSizeType } from '../../types';

export type AccordionProps = {
  title?: string;
  intro?: string;
  decorations?: DecorationProps[];
  items?: AccordionItemType[];
  headingSize?: HeadingSizeType;
  markAsFAQ?: boolean;
} & Partial<BackgroundProps>;

export const Accordion = ({
  title,
  intro,
  theme,
  items = [],
  decorations,
  headingSize,
  markAsFAQ,
}: AccordionProps) => {
  const faqJsonLd = markAsFAQ
    ? {
        '@context': 'https://schema.org',
        '@type': 'FAQPage',
        mainEntity: items
          .filter(({ title, content }) => Boolean(title) && Boolean(content))
          .map(({ title, content }) => {
            return {
              '@type': 'Question',
              name: title,
              acceptedAnswer: {
                '@type': 'Answer',
                text: ReactDOMServer.renderToStaticMarkup(content),
              },
            };
          }),
      }
    : null;

  return (
    <Wrapper theme={theme} id={title} decorations={decorations}>
      <div className="flex flex-col items-center text-center gap-10">
        {title && (
          <Title as={headingSize ?? 'h2'} size="lg" color={theme?.title}>
            {title}
          </Title>
        )}

        {intro && (
          <Text align="center" color={theme?.text}>
            {intro}
          </Text>
        )}
        {Boolean(items?.length) && (
          <div className="w-full text-left">
            <AccordionComponent items={items} />
          </div>
        )}
      </div>

      {faqJsonLd && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(faqJsonLd, null, 2) }}
        />
      )}
    </Wrapper>
  );
};

export const AccordionMemo = React.memo(Accordion);
