import React from 'react';

import { DecorationProps } from '../../components/module/Decoration';
import { Text } from '../../components/module/Text';
import { Wrapper } from '../../components/module/Wrapper';
import { ThemeType } from '../../types';

export type IntroProps = {
  theme?: ThemeType;
  intro?: React.ReactNode;
  decorations?: DecorationProps[];
};

export const Intro = ({ theme, decorations, intro }: IntroProps) => {
  return (
    <Wrapper theme={theme} decorations={decorations} space="xl">
      {intro && (
        <div className="mx-auto max-w-lg">
          <Text align="center" font="heading" size="xxl" color={theme?.text}>
            {intro}
          </Text>
        </div>
      )}
    </Wrapper>
  );
};

export const IntroMemo = React.memo(Intro);
