import cx from 'classnames';
import React from 'react';

import { backgroundClasses, borderClasses, textClasses } from '../../colors';
import { isInternalLink } from '../../helpers/sitemap/isInternalLink';
import { ColorType } from '../../types';
import { Icon } from '../icons/Icon';
import { IconName } from '../icons/Icons';
import { Spinner } from '../loaders/Spinner';
import { AlignType, SizeType, IconPositionType, WeightType } from './ButtonOptions';
import { Link } from './Link';

export type ButtonProps = {
  align?: AlignType;
  ariaLabel?: string;
  as?: 'button' | 'a' | 'div' | 'span' | 'submit';
  compact?: boolean;
  href?: string;
  icon?: IconName;
  iconPosition?: IconPositionType;
  label?: string;
  onClick?: (e: React.MouseEvent) => void;
  plain?: boolean;
  round?: boolean;
  size?: SizeType;
  stretch?: boolean;
  target?: string;
  theme?: { text?: ColorType; background?: ColorType; border?: ColorType };
  disabled?: boolean;
  loading?: boolean;
  weight?: WeightType;
  download?: boolean;
  hideLabel?: boolean;
};

const sizeClasses: Record<SizeType, string> = {
  xs: 'text-sm md:text-sm',
  sm: 'text-base md:text-md',
  md: 'text-lg md:text-xl',
};

const spaceClasses: Record<SizeType, string> = {
  xs: 'px-4 py-2 md:px-4',
  sm: 'px-4 py-2 md:px-4',
  md: 'px-5 py-[9px] md:px-6',
};

const iconSizeClasses: Record<SizeType, string> = {
  xs: 'w-5 h-5',
  sm: 'w-5 h-5',
  md: 'w-5 h-5',
};

const iconOnlySizeClasses: Record<SizeType, string> = {
  xs: 'w-10 h-10 md:w-10 md:h-10',
  sm: 'w-10 h-10 md:w-10 md:h-10',
  md: 'w-10 h-10 md:w-11 md:h-11',
};

const alignClasses: Record<AlignType, string> = {
  left: 'justify-start',
  center: 'justify-center',
  right: 'justify-end',
};

const weightClasses: Record<WeightType, string> = {
  thin: 'font-thin',
  extralight: 'font-extralight',
  light: 'font-light',
  normal: 'font-normal',
  regular: 'font-normal',
  medium: 'font-medium',
  semibold: 'font-semibold',
  bold: 'font-bold',
  extrabold: 'font-extrabold',
  black: 'font-black',
};

export const Button = (props: ButtonProps) => {
  if (isInternalLink(props.href)) {
    return (
      <Link href={props.href}>
        <ButtonInner {...props} as="span" />
      </Link>
    );
  }

  return <ButtonInner {...props} />;
};

export const ButtonMemo = React.memo(Button);

const ButtonInner = ({
  as = 'a',
  label = '',
  href,
  onClick,
  target,
  size = 'md',
  theme = { text: 'white', background: 'black' },
  stretch = false,
  round = true,
  icon,
  iconPosition = 'after',
  plain = false,
  compact = false,
  ariaLabel,
  align = 'center',
  disabled = false,
  loading = false,
  weight = 'medium',
  download = false,
  hideLabel = false,
}: ButtonProps) => {
  const Element = as === 'submit' ? 'button' : as;
  const props = {
    type: null,
    href: null,
    target: null,
    download: null,
    title: null,
  };

  if (hideLabel) {
    ariaLabel = props.title = label;
    label = '';
  }

  label = label || '';
  iconPosition = iconPosition || 'after';

  // prevent orphan icon by adding first / last word to icon
  const labelWords = label?.split(' ');

  if (as === 'button') {
    props.type = 'button';
  }

  if (as === 'submit') {
    props.type = 'submit';
  }

  if (as === 'a') {
    props.href = href;
    props.target = target;
  }

  if (download) {
    props.download = true;
    if (props.href?.indexOf('.sanity.io') > -1) props.href = `${props.href}?dl`;
  }

  const handleClick = (e: React.MouseEvent) =>
    disabled ? () => {} : onClick ? onClick(e) : () => {};

  const ButtonIcon = icon
    ? ({ wordBefore, wordAfter }: { wordBefore?: string; wordAfter?: string }) => (
        <span className=" whitespace-nowrap break-all">
          {wordBefore && ` ${wordBefore}`}
          <Icon
            name={icon}
            className={cx(
              'inline text-current transform -translate-y-px',
              iconSizeClasses[size],
              {
                ['mr-2']: wordAfter,
                ['ml-2']: wordBefore,
              },
            )}
          />
          {wordAfter && `${wordAfter} `}
        </span>
      )
    : null;

  const sharedClasses = {
    ['cursor-pointer']: true,
    ['border']: theme?.border,
    ['transition-colors duration-200']: true,
    ['rounded-full']: round,
    [backgroundClasses[theme?.background]]: true,
    ['bg-gradient-to-r from-[rgba(0,0,0,.05)] to-[rgba(0,0,0,0)]']: Boolean(
      theme?.background,
    ),
    [borderClasses[theme?.border]]: theme?.border,
    [textClasses[theme?.text]]: true,
    ['inline-flex items-center justify-center']: !stretch,
    ['bg-opacity-0 border-opacity-0']: plain,
    ['hover:bg-opacity-0 focus:bg-opacity-0']: plain,
    ['hover:underline focus:underline underline-offset-4 decoration-from-font']:
      true,
    ['pointer-events-none opacity-75']: disabled,
    [weightClasses[weight]]: true,
  };

  // icon only button
  if (!label?.trim().length) {
    return (
      <Element
        {...props}
        aria-label={ariaLabel || label}
        onClick={handleClick}
        className="btn"
      >
        <span
          className={cx(sharedClasses, { [iconOnlySizeClasses[size]]: !compact })}
        >
          {ButtonIcon && <ButtonIcon />}
          {loading && <ButtonLoader />}
        </span>
      </Element>
    );
  }

  // icon + text button
  return (
    <Element
      {...props}
      aria-label={ariaLabel || label}
      onClick={handleClick}
      className="btn"
    >
      <span
        className={cx(
          sharedClasses,
          sizeClasses[size],
          alignClasses[align],
          { ['w-full flex']: stretch },
          { ['rounded-full']: round },
          { [spaceClasses[size]]: !compact },
        )}
      >
        <span className="no-underline text-left break-words">
          {ButtonIcon ? (
            <>
              {ButtonIcon && iconPosition === 'before' && (
                <ButtonIcon wordAfter={labelWords[0]} />
              )}
              {iconPosition === 'before'
                ? labelWords.slice(1).join(' ')
                : labelWords.slice(0, -1).join(' ')}
              {ButtonIcon && iconPosition === 'after' && (
                <ButtonIcon wordBefore={labelWords[labelWords.length - 1]} />
              )}
              {loading && <ButtonLoader />}
            </>
          ) : (
            <span className="flex">
              {label}
              {loading && <ButtonLoader />}
            </span>
          )}
        </span>
      </span>
    </Element>
  );
};

const ButtonLoader = () => (
  <span className="h-5 w-5 inline-flex self-center align-middle ml-2 -mb-1">
    <Spinner />
  </span>
);
