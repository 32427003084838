import cx from 'classnames';
import React from 'react';

import { backgroundClasses } from '../../colors';
import { Background } from '../../components/module/Background';
import { ThemeType } from '../../types';
import { SizeType } from './DividerOptions';

export type DividerProps = {
  size?: SizeType;
  theme?: ThemeType;
};

export const Divider = ({ theme, size = 'xl' }: DividerProps) => {
  return (
    <Background theme={theme}>
      <div
        className={cx('h-2 rounded-full', backgroundClasses[theme?.foreground], {
          ['w-full']: size === 'xl',
          ['mx-auto px-4']: size !== 'xl',
          ['w 1/4 md:w-10']: size === 'xs',
          ['w 1/3 md:w-64']: size === 'sm',
          ['w-1/2 md:w-full md: max-w-text']: size === 'md',
          ['w-full max-w-xl']: size === 'lg',
        })}
      />
    </Background>
  );
};

export const DividerMemo = React.memo(Divider);
