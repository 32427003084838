import * as RadixAccordion from '@radix-ui/react-accordion';
import cx from 'classnames';
import React from 'react';
import tinycolor from 'tinycolor2';

import { backgroundClasses, borderClasses, textClasses } from '../colors';
import { AccordionItemType } from '../components/Accordion/Accordion';
import { AnimateInView } from '../components/animation/AnimateInView';
import { ButtonProps } from '../components/buttons/Button';
import { ButtonGroup } from '../components/buttons/ButtonGroup';
import { ScribbleUnderline } from '../components/canvas/ScribbleUnderline';
import { Icon } from '../components/icons/Icon';
import { Image } from '../components/images/Image';
import { BackgroundProps } from '../components/module/Background';
import { DecorationProps } from '../components/module/Decoration';
import { Title } from '../components/module/Title';
import { Wrapper } from '../components/module/Wrapper';
import { Video } from '../components/video/Video';
import { PortableText } from '../layout/ModuleBuilder/PortableText';
import { ColorType, HeadingSizeType, ImageType, VideoType } from '../types';
import { TitleHighlightType } from './HeroSimpleOptions';

export type HeroSimpleProps = {
  title?: string;
  titleTheme?: {
    highlightColor?: ColorType;
    highlightWord?: string;
    highlightType?: TitleHighlightType;
  };
  label?: string;
  intro?: React.ReactElement;
  children?: React.ReactNode;
  buttons?: ButtonProps[];
  video?: VideoType;
  image?: ImageType;
  decorations?: DecorationProps[];
  headingSize?: HeadingSizeType;
  accordion?: { title?: string; items?: AccordionItemType[] };
} & Partial<BackgroundProps>;

export const HeroSimple = ({
  title,
  titleTheme,
  label,
  intro,
  theme = { background: 'white' },
  buttons,
  children,
  decorations,
  video,
  image,
  headingSize,
  accordion,
}: HeroSimpleProps) => {
  var isVideoOrImage = (video && video?.provider) || image;

  const isAccordion = Boolean(accordion);
  const isSplit = isVideoOrImage || isAccordion;

  return (
    <Wrapper space="xl" theme={theme} decorations={decorations}>
      <div
        className={cx('relative', {
          ['grid xl:grid-cols-12 gap-12']: isVideoOrImage,
          ['grid lg:grid-cols-12 gap-12']: isAccordion,
        })}
      >
        <div
          className={cx('min-h-[40vh] flex flex-col', {
            ['text-center justify-center max-w-title mx-auto']: true,
            ['lg:text-left lg:justify-start lg:max-w-full lg:mx-0 lg:col-span-7']:
              isSplit,
          })}
        >
          <div
            className={cx({
              ['sm:text-center lg:text-left']: isSplit,
            })}
          >
            {label && (
              <div>
                <span
                  className={cx(
                    'rounded-full text-black inline-block pt-[5px] pb-1 px-3 font-semibold text-md uppercase tracking-wider',
                    {
                      ['mb-9']: title,
                    },
                    backgroundClasses[theme?.labelBackground],
                    textClasses[theme?.label],
                  )}
                >
                  {label}
                </span>
              </div>
            )}
            <h1>
              {title && (
                <Title
                  size="xxl"
                  as={headingSize ?? 'span'}
                  className={cx(`tracking-tight ${textClasses[theme?.title]}`, {
                    ['text-center']: !isSplit,
                  })}
                >
                  <span
                    className={cx(
                      'underline decoration-navy-50 underline-offset-8 text-3xl md:text-4xl lg:text-5xl',
                      {
                        ['text-center']: !isSplit,
                      },
                    )}
                  >
                    {title.split('.')[0]}.
                  </span>
                  <br />
                  <span
                    style={{ lineHeight: 1.7 }}
                    className={cx(
                      'mt-6 tracking inline-block w-[80%] text-xl lg:text-2xl font-medium',
                      {
                        ['text-center']: !isSplit,
                      },
                    )}
                  >
                    {title.split('.')[1]}
                  </span>
                </Title>
              )}
            </h1>

            {(intro || children) && (
              <div
                className={cx('text-lg sm:text-xl md:text-xl font-medium', {
                  ['2xl:mx-0']: isSplit,
                  ['text-center mx-auto']: !isSplit,
                })}
              >
                {intro && <div className="mt-8 sm:mt-10">{intro}</div>}
                {children && <div className="mt-8 sm:mt-10">{children}</div>}
              </div>
            )}
            {Boolean(buttons?.length) && (
              <AnimateInView delay={0.15} className="mt-8 sm:mt-16 relative">
                {isSplit && (
                  <span
                    className={cx(
                      'absolute left-0 top-0 animate-ping w-1 h-1 bg-navy-400 rounded-full',
                      backgroundClasses[buttons[0]?.theme?.background],
                      borderClasses[buttons[0]?.theme?.border],
                    )}
                  />
                )}
                <ButtonGroup items={buttons} />
              </AnimateInView>
            )}
          </div>
        </div>
        {isVideoOrImage && (
          <div
            className={cx({
              ['w-full max-w-title mx-auto 2xl:mx-0 2xl:max-w-full xl:col-span-5 min-h-full flex flex-col justify-center relative']:
                true,
            })}
          >
            <div className="aspect-video overflow-hidden 2xl:overflow-visible rounded-xl relative w-full">
              <div
                className={cx(
                  '2xl:absolute',
                  '2xl:-translate-y-1/2',
                  '2xl:top-1/2 2xl:bottom-auto 2xl:right-auto',
                  '2xl:w-[calc(1200px/12*5+(50vw-1280px/2)-20px)]',
                  '2xl:aspect-video 2xl:overflow-hidden 2xl:rounded-xl',
                )}
              >
                {video && <Video {...video} />}
                {image && <Image alt="Hero image" {...image} />}
              </div>
            </div>
          </div>
        )}

        {isAccordion && (
          <HeroAccordion title={accordion.title} items={accordion.items} />
        )}
      </div>
    </Wrapper>
  );
};

export const HeroSimpleMemo = React.memo(HeroSimple);

const HighlightTitle = ({
  children,
  theme,
  background,
}: {
  children: string;
  background?: ColorType;
  theme: HeroSimpleProps['titleTheme'];
}) => {
  if (!theme?.highlightWord || children.indexOf(theme?.highlightWord) == -1)
    return <span>{children}</span>;

  const [startIndex, endIndex] = [
    children.indexOf(theme?.highlightWord),
    children.indexOf(theme?.highlightWord) + theme?.highlightWord.length - 1,
  ];

  const first = <span>{children.substring(0, startIndex)}</span>;
  const last = <span>{children.substring(endIndex + 1, children.length)}</span>;
  const highlight = (
    <span>
      <span
        className={cx(textClasses[theme?.highlightColor], 'relative inline-block')}
      >
        {theme?.highlightWord}
        {theme.highlightType === 'scribble-underline' && (
          <ScribbleUnderline
            key={children.toString()}
            className={cx(
              'absolute top-[95%] left-0 right-0',
              tinycolor(background).isDark()
                ? 'mix-blend-soft-light'
                : 'mix-blend-multiply',
            )}
            color={theme?.highlightColor}
          />
        )}
      </span>
    </span>
  );

  return (
    <>
      {first}
      {highlight}
      {last}
    </>
  );
};

const HeroAccordion = ({
  title,
  items,
}: {
  title?: string;
  items: HeroSimpleProps['accordion']['items'];
}) => {
  return (
    <div className="w-full h-full hidden lg:block lg:col-span-5 font-heading relative overflow-hidden pb-5 px-3">
      <div className="p-5 pb-8 relative">
        <span className="absolute top-6 right-3 left-10 bottom-1 bg-white rounded-xl shadow-[0px_4px_10px_rgba(0,0,0,0.07)] transform rotate-[6deg] translate-x-4 z-10" />
        <div className="absolute top-0 right-0 left-0 bottom-2 bg-white rounded-xl shadow-[0px_4px_10px_rgba(0,0,0,0.07)] z-20" />
        <div className="flex flex-col relative z-30">
          {title && <h2 className="font-medium text-navy-600">{title}</h2>}

          <RadixAccordion.Root
            className="radix-accordion mt-3"
            type="single"
            defaultValue={items?.[0]?._key}
          >
            {Boolean(items?.length) &&
              items?.map(({ _key, title = '', content }) => (
                <RadixAccordion.Item
                  className="border border-b-gray-200 mb-2 bg-white relative"
                  value={_key}
                  key={_key}
                  id={_key}
                >
                  <RadixAccordion.Header className="">
                    <RadixAccordion.Trigger className="accordion-trigger bg-white p-2.5 text-[17px] flex items-center text-left w-full group">
                      <div className="pr-3 font-medium text-navy-600 group-hover:underline">
                        {title}
                      </div>
                      <span className="flex-shrink-0 ml-auto radix-accordion-trigger">
                        <Icon
                          name="ChevronDownIcon"
                          className="w-3 h-3 text-current"
                        />
                      </span>
                    </RadixAccordion.Trigger>
                  </RadixAccordion.Header>
                  <RadixAccordion.Content className="p-2.5 pt-0 pr-10 max-h-[230px] overflow-scroll">
                    <div className="prose prose-sm prose-p:leading-relaxed prose-a:text-purple-600 prose-a:hover:no-underline prose-a:font-normal prose-p:font-heading">
                      <PortableText content={content} />
                    </div>
                  </RadixAccordion.Content>
                  <div className="absolute bg-gradient-to-b from-white/0 to-white bottom-0 left-0 right-0 h-5 pointer-events-none" />
                </RadixAccordion.Item>
              ))}
          </RadixAccordion.Root>
        </div>
      </div>
    </div>
  );
};
