import cx from 'classnames';
import { useRef, useState } from 'react';
import { Keyboard, Pagination, Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';

import { textClasses } from '../../colors';
import { GapType } from '../../modules/CardGrid/CardGridOptions';
import { ColorType, ThemeType } from '../../types';
import { AlignType } from '../buttons/ButtonGroupOptions';
import { ColumnType } from '../cards/CardRowOptions';
import { Icon } from '../icons/Icon';

export type SliderProps = {
  slides?: React.ReactElement[];
  columns?: number;
  gap?: number;
  theme?: any;
};

export const Slider = ({ slides, columns, gap = 0, theme }: SliderProps) => {
  const [prevEl, setPrevEl] = useState<HTMLElement | null>(null);
  const [nextEl, setNextEl] = useState<HTMLElement | null>(null);

  if (!slides?.filter(Boolean).length) return null;

  return (
    <div className="relative">
      <Swiper
        spaceBetween={gap}
        style={
          theme?.cardBackground === 'white'
            ? {
                // @ts-ignore
                '--swiper-pagination-color': '#fff',
                '--swiper-pagination-bullet-inactive-color': '#fff',
                '--swiper-pagination-bullet-inactive-opacity': '0.5',
              }
            : null
        }
        slidesPerView={columns}
        // slidesPerGroup={columns} // this sort of breaks dragging behaviour, removing it for now
        grabCursor={!slides?.some(({ props }) => props.href)}
        modules={[Keyboard, Pagination, Navigation]}
        // preventClicks={false}
        // preventClicksPropagation={false}
        navigation={{ prevEl, nextEl }}
        // autoHeight={true}
        keyboard={{
          enabled: true,
        }}
        pagination={{
          clickable: true,
          type: 'bullets',
        }}
      >
        {slides?.filter(Boolean).map((slide, n) => (
          <SwiperSlide className="self-stretch" key={n}>
            <div
              className={cx('h-full select-none', {
                ['swiper-no-swiping']: slide.props.href,
              })}
            >
              {slide}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      <button
        type="button"
        className="absolute left-0 -translate-y-1/2 2xl:top-1/2 z-10 2xl:-translate-y-1/2 2xl:-translate-x-full -mt-4"
        ref={(node) => setPrevEl(node)}
      >
        <Icon
          name="ChevronDownIcon"
          className={`w-12 h-12 rotate-90 ${
            theme?.cardBackground === 'white' ? 'text-white' : null
          }`}
        />
      </button>
      <button
        type="button"
        className="absolute right-0 -translate-y-1/2 2xl:top-1/2 z-10 2xl:-translate-y-1/2 2xl:translate-x-full -mt-4"
        ref={(node) => setNextEl(node)}
      >
        <Icon
          name="ChevronDownIcon"
          className={`${
            theme?.cardBackground === 'white' ? 'text-white' : null
          } w-12 h-12 -rotate-90`}
        />
      </button>
    </div>
  );
};
