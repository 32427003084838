import * as RadixAccordion from '@radix-ui/react-accordion';
import React from 'react';

import { Button } from '../buttons/Button';

export type AccordionItemType = {
  _key?: string;
  title: string;
  content: React.ReactNode | React.ReactElement;
};

export type AccordionProps = {
  items: AccordionItemType[];
};

export const Accordion = ({ items }: AccordionProps) => {
  if (!Boolean(items.filter(Boolean)?.length)) return null;

  return (
    <div className="accordion-wrapper radix-accordion relative">
      <div className="absolute inset-0 max-h-full border border-gray-200 shadow-md bg-transparent z-20 pointer-events-none" />
      <div className="absolute left-5 top-2 w-[calc(100%-20px)] h-[420px] max-h-full transform rotate-3 border border-gray-200 bg-white z-0" />
      <RadixAccordion.Root
        type="multiple"
        className="accordion-root divide-y divide-gray-200 relative z-10"
      >
        {items?.map(({ _key, title = '', content }) => (
          <RadixAccordion.Item
            value={_key}
            key={_key}
            id={_key}
            className="radix-scroll-margin"
          >
            <RadixAccordion.Header className="accordion-header bg-white hover:underline radix-accordion-title">
              <RadixAccordion.Trigger className="accordion-trigger bg-white p-5 md:p-7 lg:p-8 text-base md:text-md lg:text-lg flex items-center w-full radix-accordion-trigger">
                <div className="pr-3 text-left font-heading text-xl">{title}</div>
                <span className="flex-shrink-0 ml-auto">
                  <Button
                    icon="ChevronDownIcon"
                    label=""
                    as="div"
                    theme={{ background: 'black', text: 'white' }}
                  />
                </span>
              </RadixAccordion.Trigger>
            </RadixAccordion.Header>
            <RadixAccordion.Content className="accordion-content bg-white radix-accordion-content overflow-hidden">
              <div className="prose prose-sm md:prose break-words h-full p-5 md:p-7 lg:p-8 pt-0 md:pt-0 lg:pt-0 max-w-accordion">
                {content}
              </div>
            </RadixAccordion.Content>
          </RadixAccordion.Item>
        ))}
      </RadixAccordion.Root>
    </div>
  );
};

export const AccordionMemo = React.memo(Accordion);
