import React from 'react';

import { Link } from '../../components/buttons/Link';
import { BackgroundProps } from '../../components/module/Background';
import { Title } from '../../components/module/Title';
import { Wrapper } from '../../components/module/Wrapper';
import { SitemapType } from '../../queries/sitemap';
import { HeadingSizeType } from '../../types';

export type SitemapProps = {
  title: string;
  sitemap: SitemapType;
  headingSize?: HeadingSizeType;
} & Partial<BackgroundProps>;

export const Sitemap = ({ sitemap, title, theme, headingSize }: SitemapProps) => {
  let tree = convertSitemapToTree(sitemap);

  return (
    <Wrapper theme={theme}>
      {title && (
        <Title as={headingSize ?? 'h2'} size="xl">
          {title}
        </Title>
      )}
      <div className="mt-6">
        {tree.shift().children.map((level1) => {
          return (
            <div>
              {level1.children.length < 1 ? (
                <Link href={level1.path} className="underline">
                  {level1.name}
                </Link>
              ) : (
                <p className="mt-4 mb-1 font-bold">{level1.name}</p>
              )}
              <div>
                {level1.children.map((level2) => {
                  return (
                    <div>
                      &nbsp;&nbsp;&nbsp;
                      <Link className="underline" href={level2.path}>
                        {level2.name}
                      </Link>
                      <div>
                        {level2.children.map((level3) => {
                          return (
                            <div>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              <Link className="underline" href={level3.path}>
                                {level3.name}
                              </Link>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </Wrapper>
  );
};

export const SitemapMemo = React.memo(Sitemap);

function convertSitemapToTree(sitemap: SitemapType): SitemapType {
  let tree: SitemapType = [];

  sitemap.forEach((item) => {
    if (item.excludeFromSitemap) {
      return;
    }

    let parts = item.path.split('/');
    let currentLevel = tree;

    parts.forEach((part, index) => {
      let existing = currentLevel.filter((node) => node.title === part)[0];

      if (!existing) {
        existing = {
          _id: item._id,
          _type: item._type,
          title: part,
          path: item.path,
          name: item.title,
          _updatedAt: item._updatedAt,
          excludeFromSitemap: item.excludeFromSitemap,
          children: [],
        };
        currentLevel.push(existing);
      }

      if (index !== parts.length - 1) {
        currentLevel = existing.children;
      }
    });
  });

  return tree;
}
