import React from 'react';

import { FigCaptionMemo as FigCaption } from '../../components/images/FigCaption';
import { VideoMemo as VideoComponent } from '../../components/video/Video';
import { AccordionMemo as Accordion } from '../../modules/Accordion/Accordion';
import { BlogFeedMemo as BlogFeed } from '../../modules/BlogFeed/BlogFeed';
import { BlogGridMemo as BlogGrid } from '../../modules/BlogGrid/BlogGrid';
import { BreadcrumbMemo as Breadcrumb } from '../../modules/Breadcrumb/Breadcrumb';
import { CallToActionMemo as CallToAction } from '../../modules/CallToAction/CallToAction';
import { CardGridMemo as CardGrid } from '../../modules/CardGrid/CardGrid';
import { DividerMemo as Divider } from '../../modules/Divider/Divider';
import { FormMemo as Form } from '../../modules/Form/Form';
import { IntroMemo as Intro } from '../../modules/Intro/Intro';
import { LogoCloudMemo as LogoCloud } from '../../modules/LogoCloud/LogoCloud';
import { RichTextMemo as RichText } from '../../modules/RichText/RichText';
import { SitemapMemo as Sitemap } from '../../modules/Sitemap/Sitemap';
import { TestimonialSliderMemo as TestimonialSlider } from '../../modules/TestimonialSlider/TestimonialSlider';
import { TextImageMemo as TextImage } from '../../modules/TextImage/TextImage';
import { VideoMemo as VideoModule } from '../../modules/Video/Video';
import { WorkGridMemo as WorkGrid } from '../../modules/WorkGrid/WorkGrid';
import { ModuleSchemaName } from '../../types.sanity';
import { PortableTextMemo as PortableText } from './PortableText';
import { StaticFormBuilderMemo as StaticFormBuilder } from './StaticFormBuilder';

export type ModuleBuilderProps = {
  items: any[]; // TODO: type all modules
};

// TODO: losing all typing here, there's no connection between queried modules and props for now
type ModuleProps = any & {
  _type: ModuleSchemaName;
  _key: string;
  [key: string]: any;
};

export const ModuleBuilder = ({ items }: ModuleBuilderProps) => {
  return (
    <>
      {items?.map((item: ModuleProps) => (
        <React.Fragment key={item._key}>
          {/* text image */}
          {item._type === 'module.textimage' && (
            <TextImage
              {...item}
              content={<PortableText content={item.intro} />}
              video={
                item.video?.videoId && (
                  <figure>
                    {item.video && (
                      <div className="mb-3 aspect-video relative">
                        <VideoComponent {...item.video} />
                      </div>
                    )}
                    {item.video.caption && (
                      <FigCaption caption={item.video.caption} />
                    )}
                  </figure>
                )
              }
            />
          )}

          {/* call to action */}
          {item._type === 'module.calltoaction' && (
            <CallToAction {...item} intro={<PortableText content={item.intro} />}>
              {item.form?.formId && (
                <StaticFormBuilder
                  {...item.form}
                  className="mt-8 w-96 inline-block"
                />
              )}
            </CallToAction>
          )}

          {/* testimonial slider */}
          {item._type === 'module.testimonialslider' && (
            <TestimonialSlider
              {...item}
              items={item.items?.map((item) => ({
                ...item,
                image: {
                  ...item.image,
                  width: (item.image?.width / item.image?.height) * 96,
                  height: 96,
                },
                content: <PortableText content={item.content} />,
              }))}
            />
          )}

          {/* logo cloud */}
          {item._type === 'module.logocloud' && <LogoCloud {...item} />}

          {/* rich text */}
          {item._type === 'module.richtext' && (
            <RichText {...item} content={<PortableText content={item.content} />} />
          )}

          {/* video */}
          {item._type === 'module.video' && (
            <VideoModule {...item} intro={<PortableText content={item.intro} />} />
          )}

          {/* Accordion */}
          {item._type === 'module.accordion' && (
            <Accordion
              {...item}
              items={item.items?.map((accordionItem) => ({
                ...accordionItem,
                content: <PortableText content={accordionItem.content} />,
              }))}
            />
          )}

          {/* blog feed */}
          {item._type === 'module.blogfeed' && <BlogFeed {...item} />}

          {/* Divider */}
          {item._type === 'module.divider' && <Divider {...item} />}

          {/* Intro */}
          {item._type === 'module.intro' && (
            <Intro {...item} intro={<PortableText content={item.intro} />} />
          )}

          {/* Form */}
          {item._type === 'module.form' && (
            <Form
              {...item}
              intro={<PortableText content={item.intro} />}
              form={
                item.form?.formId && (
                  <StaticFormBuilder {...item.form} className="" />
                )
              }
            />
          )}

          {/* Breadcrumb */}
          {item._type === 'module.breadcrumb' && <Breadcrumb {...item} />}

          {/* Card Grid */}
          {item._type === 'module.cardgrid' && (
            <CardGrid
              {...item}
              intro={item.intro ? <PortableText content={item.intro} /> : null}
              items={item.items?.map((item) => ({
                ...item,
                text: item?.text ? (
                  typeof item?.text === 'string' ? (
                    item.text
                  ) : (
                    <PortableText content={item.text} />
                  )
                ) : null,
              }))}
            />
          )}

          {/* Sitemap */}
          {item._type === 'module.sitemap' && <Sitemap {...item} />}

          {/* Work Grid */}
          {item._type === 'module.workgrid' && (
            <WorkGrid {...item} intro={<PortableText content={item.intro} />} />
          )}

          {/* Blog Grid */}
          {item._type === 'module.bloggrid' && <BlogGrid {...item} />}
        </React.Fragment>
      ))}
    </>
  );
};

export const ModuleBuilderMemo = React.memo(ModuleBuilder);
