import cx from 'classnames';
import React from 'react';

import { AnimateInView } from '../../components/animation/AnimateInView';
import { ButtonProps } from '../../components/buttons/Button';
import { ButtonGroup } from '../../components/buttons/ButtonGroup';
import { FigCaption } from '../../components/images/FigCaption';
import { Image } from '../../components/images/Image';
import { LottieHoverPlayer } from '../../components/images/LottieHoverPlayer';
import { LottiePlayer } from '../../components/images/LottiePlayer';
import { DecorationProps } from '../../components/module/Decoration';
import { SpacingType } from '../../components/module/Spacing';
import { Text } from '../../components/module/Text';
import { Title } from '../../components/module/Title';
import { Wrapper } from '../../components/module/Wrapper';
import { useHover } from '../../hooks/useHover';
import { HeadingSizeType, ImageType, LottieType, ThemeType } from '../../types';
import { AlignType } from './TextImageOptions';

export type TextImageProps = {
  image?: ImageType;
  lottie?: LottieType;
  align?: AlignType;
  content?: React.ReactElement;
  video?: React.ReactElement;
  theme?: ThemeType & { spacing?: SpacingType };
  title?: string;
  children?: React.ReactElement | React.ReactNode;
  buttons?: ButtonProps[];
  decorations?: DecorationProps[];
  headingSize?: HeadingSizeType;
};

export const TextImage = ({
  title,
  buttons,
  image,
  theme,
  align = 'right',
  content,
  children,
  video,
  lottie,
  decorations,
  headingSize,
}: TextImageProps) => {
  if (video) image = null;
  const [hoverRef, isHovered] = useHover<HTMLDivElement>();

  return (
    <Wrapper
      theme={theme}
      id={title}
      space={theme?.spacing || 'sm'}
      decorations={decorations}
    >
      <div
        className="grid grid-cols-1 md:grid-cols-2 items-center md:items-start lg:items-center gap-10 md:gap-18 lg:gap-24"
        ref={hoverRef}
      >
        {content && (
          <div className="order-2">
            {title && (
              <AnimateInView y={5} delay={0.1}>
                <div className="mb-4 sm:mb-8">
                  <Title
                    as={headingSize ?? 'h2'}
                    size="sm"
                    color={theme?.title}
                    weight="extrabold"
                  >
                    {title}
                  </Title>
                </div>
              </AnimateInView>
            )}

            {content && (
              <AnimateInView y={5} delay={0.15}>
                <div className="mb-6 sm:mb-8">
                  <Text color={theme?.text} as="div">
                    {content}
                  </Text>
                </div>
              </AnimateInView>
            )}

            {Boolean(buttons?.length) && (
              <AnimateInView y={5} delay={0.2}>
                <ButtonGroup items={buttons} />
              </AnimateInView>
            )}
          </div>
        )}

        {(image || video || lottie) && (
          <AnimateInView
            y={30}
            className={cx(
              align === 'left' ? 'order-1 md:order-1' : 'order-1 md:order-3',
            )}
          >
            <figure>
              {video ? (
                <div className="aspect-video relative">{video}</div>
              ) : lottie?.url?.trim().length ? (
                <LottieHoverPlayer {...lottie} isHovered={isHovered} />
              ) : (
                <>
                  <div
                    className={cx('relative flex md:justify-center aspect-square')}
                  >
                    <Image {...image} layout="fill" />
                  </div>
                  {image.caption && <FigCaption caption={image.caption} />}
                </>
              )}
            </figure>
          </AnimateInView>
        )}
      </div>

      {children && <div className="mt-6 md:mt-8 lg:mt-10">{children}</div>}
    </Wrapper>
  );
};

export const TextImageMemo = React.memo(TextImage);
