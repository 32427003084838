import React from 'react';

import { Slider, SliderProps } from '../../components/Testimonial/Slider';
import { AnimateInView } from '../../components/animation/AnimateInView';
import { Image } from '../../components/images/Image';
import { DecorationProps } from '../../components/module/Decoration';
import { Wrapper } from '../../components/module/Wrapper';
import { ImageType } from '../../types';

export type TestimonialSliderProps = {
  backgroundImage?: ImageType;
  decorations?: DecorationProps[];
} & SliderProps;

export const TestimonialSlider = ({
  theme,
  icon = 'QuoteIcon',
  decorations,
  items,
}: TestimonialSliderProps) => {
  return (
    <Wrapper theme={theme} decorations={decorations}>
      <div className="relative">
        <Slider items={items} theme={theme} icon={icon} />
      </div>
    </Wrapper>
  );
};

export const TestimonialSliderMemo = React.memo(TestimonialSlider);
