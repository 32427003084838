import cx from 'classnames';

import { backgroundClasses, textClasses } from '../../colors';
import { ImageType, ThemeType } from '../../types';
import { Image } from '../images/Image';
import DecorationDots from './DecorationDots';
import {
  HorizontalAlignType,
  DecorationType,
  VerticalAlignType,
  SizeType,
} from './DecorationOptions';
import DecorationWaves from './DecorationWaves';

export type DecorationProps = {
  theme?: ThemeType;
  horizontalAlign?: HorizontalAlignType;
  offset?: number;
  size?: SizeType;
  decorationType?: DecorationType;
  verticalAlign?: VerticalAlignType;
  image?: ImageType;
  points?: number;
};

const sizeClasses: Record<SizeType, string> = {
  sm: 'w-[25%] h-[25%]',
  md: 'w-[50%] h-[50%]',
  lg: 'w-[75%] h-[75%]',
  xl: 'w-[100%] h-[100%]',
};

const wavesClasses: Record<SizeType, string> = {
  sm: 'w-[25%] h-[25%]',
  md: 'w-[50%] h-[25%]',
  lg: 'w-[75%] h-[25%]',
  xl: 'w-[100%] h-[25%]',
};

const horizontalAlignClasses: Record<HorizontalAlignType, string> = {
  left: 'left-0',
  right: 'right-0',
  center: 'left-1/2 -translate-x-1/2',
  insideLeft: 'left-0',
  insideRight: 'right-0',
};

const verticalAlignClasses: Record<VerticalAlignType, string> = {
  top: 'top-0 items-start',
  middle: 'top-1/2 -translate-y-1/2 items-center',
  bottom: 'bottom-0 items-end',
};

export const Decoration = ({
  theme,
  horizontalAlign = 'left',
  size = 'lg',
  decorationType = 'square',
  verticalAlign = 'top',
  image,
  points = 1,
}: DecorationProps) => (
  <div
    className={cx({
      ['absolute inset-0 h-full max-w-xl w-full mx-auto']:
        horizontalAlign === 'insideLeft' || horizontalAlign === 'insideRight',
    })}
  >
    <div
      className={cx(
        'absolute z-0',
        'flex',
        textClasses[theme?.color],
        decorationType === 'waves' ? wavesClasses[size] : sizeClasses[size],
        horizontalAlignClasses[horizontalAlign],
        verticalAlignClasses[verticalAlign],
      )}
    >
      {decorationType === 'square' && (
        <DecorationSquare image={image} color={theme?.color} />
      )}
      {decorationType === 'waves' && (
        <DecorationWaves
          points={points}
          verticalAlign={verticalAlign}
          horizontalAlign={horizontalAlign}
          color={theme?.color}
        />
      )}
      {decorationType === 'dots' && (
        <DecorationDots
          verticalAlign={verticalAlign}
          horizontalAlign={horizontalAlign}
          color={theme?.color}
        />
      )}
    </div>
  </div>
);

export const DecorationSquare = ({ image, color }) => (
  <div className={cx('w-full h-full', backgroundClasses[color])}>
    <Image {...image} layout="fill" alt="Decoration square" />
  </div>
);
