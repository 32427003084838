import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';

import { ButtonProps } from '../../components/buttons/Button';
import { IconName } from '../../components/icons/Icons';
import { BREAKPOINTS, useBreakpoint } from '../../hooks/useBreakpoint';
import { useScrollDirection } from '../../hooks/useScrollDirection';
import { useScrollPosition } from '../../hooks/useScrollPosition';
import { MobileNav } from './MobileNav';
import { TopNav } from './TopNav';

export type NavItem = {
  label?: string;
  href?: string;
  current?: boolean;
  children?: {
    label?: string;
    href?: string;
    description?: string;
    icon?: IconName;
    current?: boolean;
  }[];
};

export type NavProps = {
  showSearch?: boolean;
  items: NavItem[];
  buttons: ButtonProps[];
  roundedRight?: boolean;
  roundedLeft?: boolean;
};

export const Nav = ({
  items,
  buttons,
  showSearch,
  roundedRight,
  roundedLeft,
}: NavProps) => {
  const router = useRouter();
  const { screenWidth, breakpoint } = useBreakpoint();
  const scrollDirection = useScrollDirection();
  const scrollPosition = useScrollPosition();

  const [mouseOver, setMouseOver] = useState<boolean>(false);
  const showNav =
    mouseOver || scrollDirection === 'up' || scrollPosition !== 'middle';

  roundedLeft = scrollPosition === 'top' ? roundedLeft : true;
  roundedRight = scrollPosition === 'top' ? roundedRight : true;

  const navRef = useRef(null);
  const [spacerHeight, setSpacerHeight] = useState<number>(94);

  const [mobileNavIsOpen, setMobileNavIsOpen] = useState<boolean>(false);

  /**
   * Measure nav and create a spacer of equal height
   */

  useEffect(() => {
    if (!navRef.current) return;
    let navHeight = navRef.current.getBoundingClientRect().height;
    if (navHeight > 94) navHeight = 94; // HACK: fix sizing bug after refresh
    setSpacerHeight(navHeight + 6);
  }, [navRef.current, breakpoint]);

  const onHamburgerClick = () => {
    setMobileNavIsOpen(true);
  };

  useEffect(() => {
    function onRouteChange() {
      setMobileNavIsOpen(false);
    }

    router.events.on('routeChangeStart', onRouteChange);
    () => router.events.off('routeChangeStart', onRouteChange);
  }, []);

  return (
    <div
      onMouseEnter={() => setMouseOver(true)}
      onMouseLeave={() => setMouseOver(false)}
    >
      <div style={{ height: spacerHeight }} className="invisible" />

      {screenWidth < BREAKPOINTS.lg && (
        <MobileNav
          items={items}
          buttons={buttons}
          open={mobileNavIsOpen}
          onOpenChange={setMobileNavIsOpen}
        />
      )}

      <TopNav
        items={items}
        buttons={buttons}
        onHamburgerClick={onHamburgerClick}
        showNav={showNav}
        ref={navRef}
        showSearch={showSearch}
        roundedRight={roundedRight}
        roundedLeft={roundedLeft}
      />
    </div>
  );
};
