import cx from 'classnames';
import { motion } from 'framer-motion';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import React from 'react';
import tinycolor from 'tinycolor2';

import { COLORS } from '../../colors';
import { randomBetween } from '../../helpers/utils/number';
import { pointsInPath, scramble, smoothPath } from '../../helpers/utils/path';
import { HorizontalAlignType, VerticalAlignType } from './DecorationOptions';

const horizontalRotateClasses: Record<HorizontalAlignType, string> = {
  left: '-scale-x-100 ',
  right: '-scale-x-100',
  center: '',
  insideLeft: '-scale-x-100 ',
  insideRight: '',
};

const verticalRotateClasses: Record<VerticalAlignType, string> = {
  top: '-scale-y-100 -translate-y-px',
  middle: '',
  bottom: 'translate-y-px',
};

export const DecorationDots = ({ verticalAlign, horizontalAlign, color }) => {
  const router = useRouter();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);
  const [dots, setDots] = useState<{ x: number; y: number; size: number }[]>(null);
  const [polypoints, setPolypoints] = useState<string>(null);
  const [path, setPath] = useState<string>(null);
  const [pathPoints, setPathPoints] = useState<number[]>(null);

  useEffect(() => {
    function onResize() {
      if (!wrapperRef?.current) return;
      const rect = wrapperRef.current?.getBoundingClientRect();

      const height = rect.height;
      const width = rect.width;

      setWidth(width);
      setHeight(height);

      const newDots = [];

      const STEP_X = width / 5;
      const STEP_Y = height / 5;

      const newPoly = [
        width / 2,
        STEP_Y * 2.5 - 20,
        STEP_X * 5 - 20,
        STEP_Y * 1.5 - 20,
        STEP_X - 20,
        STEP_Y * 5 - 10 - 20,
        width - 10 - 20,
        STEP_Y * 3 - 10 - 20,
        STEP_X * 3 - 20,
        height - 10 - 20,
        width - 10 - 20,
        STEP_Y * 4 - 20,
      ];

      setPolypoints(newPoly.join(', '));
      setPath(smoothPath(newPoly));
      setPathPoints(newPoly);
      const pathPoints = smoothPath(scramble(newPoly));

      const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
      path.setAttribute('d', pathPoints);

      const points = pointsInPath(path, 20);

      for (let i = 0; i < points.length; i++) {
        newDots.push({
          x: points[i].x,
          y: points[i].y,
          size: 1 + i / 2,
        });
      }
      setDots(newDots.reverse());
    }

    onResize();
    router.events.on('routeChangeComplete', onResize);
    window.addEventListener('resize', onResize);
    () => {
      window.removeEventListener('resize', onResize);
      router.events.off('routeChangeComplete', onResize);
    };
  }, []);

  const DEBUG = false;
  return (
    <div
      className={cx(
        'w-full h-full',
        horizontalRotateClasses[horizontalAlign],
        verticalRotateClasses[verticalAlign],
        {
          ['border']: DEBUG,
        },
      )}
      ref={wrapperRef}
    >
      <svg viewBox={`0 0 ${width} ${height}`} fill={COLORS[color]}>
        {dots?.map((dot, n) => {
          let lineColor = tinycolor(COLORS[color]);
          const yesno = Boolean(Math.round(Math.random()));
          if (yesno) lineColor.darken(randomBetween(0, 20));
          if (!yesno) lineColor.lighten(randomBetween(0, 20));

          return (
            <motion.circle
              transition={{
                repeat: Infinity,
                repeatDelay: 0.1 + n * 0.1,
                duration: randomBetween(2.5, 4.5),
              }}
              animate={{
                scale: [1, 0.75, 1],
                opacity: [1, randomBetween(0, 0.5), 1],
              }}
              key={dot.x}
              cx={dot.x}
              cy={dot.y}
              r={dot.size}
              fill={lineColor}
            />
          );
        })}

        {DEBUG && <polyline points={polypoints} stroke="#888" fill="none" />}
      </svg>
    </div>
  );
};

export default React.memo(DecorationDots);
