import React from 'react';

import { formatDate } from '../../helpers/utils/date';
import { HeroFormMemo as HeroForm } from '../../heroes/HeroForm';
import { HeroSimpleMemo as HeroSimple } from '../../heroes/HeroSimple';
import { HeroSchemaName } from '../../types.sanity';
import { PortableTextMemo as PortableText } from './PortableText';
import { StaticFormBuilderMemo as StaticFormBuilder } from './StaticFormBuilder';

export type HeroBuilderProps = {
  hero: HeroProps; // TODO: type all modules
};

// TODO: losing all typing here, there's no connection between queried modules and props for now
type HeroProps = any & {
  _type: HeroSchemaName;
  _key: string;
  [key: string]: any;
};

export const HeroBuilder = ({ hero }: HeroProps) => {
  if (!hero) return null;

  return (
    <>
      {/* hero simple */}
      {hero._type === 'hero.simple' && (
        <HeroSimple
          {...hero}
          label={hero.label || formatDate(hero.date)}
          intro={hero.intro ? <PortableText content={hero.intro} /> : null}
        />
      )}

      {/* Hero Form */}
      {hero._type === 'hero.heroform' && (
        <HeroForm
          {...hero}
          label={hero.label || formatDate(hero.date)}
          intro={hero.intro ? <PortableText content={hero.intro} /> : null}
          form={
            hero.form?.formId && <StaticFormBuilder {...hero.form} className="" />
          }
        />
      )}
    </>
  );
};

export const HeroBuilderMemo = React.memo(HeroBuilder);
