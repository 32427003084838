import cx from 'classnames';
import { useRouter } from 'next/router';
import React from 'react';

import { PageContext } from '../../context/PageContext';
import { getBreadCrumbForPath } from '../../helpers/sitemap/getBreadCrumbForPath';
import { SitemapItemType } from '../../queries/sitemap';

export type BreadcrumbProps = {
  path?: SitemapItemType[];
};

export const Breadcrumb = ({ path }: BreadcrumbProps) => {
  const { sitemap } = React.useContext(PageContext);
  const router = useRouter();
  path = path || getBreadCrumbForPath(router?.asPath, sitemap);

  // * Prepend Home to beginning of path as per Dan's request:
  path.unshift({
    _id: '',
    _type: 'page.home',
    title: 'Home',
    path: '/',
    _updatedAt: '',
  });

  if (!path?.length) return null;

  return (
    <nav aria-label="breadcrumbs">
      <ol
        itemScope
        itemType="https://schema.org/BreadcrumbList"
        className="flex flex-wrap text-md lg:text-lg font-normal"
      >
        {path?.map((item, index) => (
          <li
            key={item.path}
            itemProp="itemListElement"
            itemScope
            itemType="https://schema.org/ListItem"
          >
            <a
              itemProp="item"
              href={item.path}
              aria-current={index === path.length - 1 ? 'location' : null}
              className={cx(
                'opacity-75 underline-offset-2 hover:underline hover:opacity-100 transition-opacity text-current',
                {
                  ['font-medium']: index === path.length - 1,
                },
              )}
            >
              <span itemProp="name">{item.title}</span>
            </a>
            <meta itemProp="position" content={`${index + 1}`} />
            {index < path.length - 1 && <span className="px-2">&rsaquo;</span>}
          </li>
        ))}
      </ol>
    </nav>
  );
};
