import React from 'react';

import { Text } from '../../components/module/Text';
import { Title } from '../../components/module/Title';
import { Wrapper } from '../../components/module/Wrapper';
import { HeadingSizeType, ThemeType } from '../../types';

export type FormProps = {
  theme?: ThemeType;
  title?: string;
  intro?: React.ReactNode;
  form?: React.ReactElement;
  headingSize?: HeadingSizeType;
};

export const Form = ({ theme, title, intro, form, headingSize }: FormProps) => {
  return (
    <Wrapper theme={theme} id={title}>
      {title && (
        <div className="mb-4 md:mb-6">
          <Title size="lg" as={headingSize ?? 'h2'} color={theme?.title}>
            {title}
          </Title>
        </div>
      )}

      {intro && (
        <div className="mb-10 md:mb-14">
          <Text color={theme?.text} as="div">
            {intro}
          </Text>
        </div>
      )}

      {form && <div className="text-center">{form}</div>}
    </Wrapper>
  );
};

export const FormMemo = React.memo(Form);
