import { BreadcrumbJsonLd, LogoJsonLd, NextSeo } from 'next-seo';
import NextHead from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import React, { useEffect } from 'react';
import TagManager from 'react-gtm-module';

import { getBreadCrumbForPath } from '../../helpers/sitemap/getBreadCrumbForPath';
import { ConfigType } from '../../queries/config';
import { PageType } from '../../queries/page';
import { SitemapType } from '../../queries/sitemap';
import { ImageType } from '../../types';
import { ModuleSchemaName } from '../../types.sanity';
import { getResponsiveImageUrl } from '../images/Image';

export type SeoProps = {
  config?: ConfigType;
  page?: PageType;
  sitemap?: SitemapType;
  preview?: boolean;
};

export const Seo = ({ config, page, sitemap, preview }: SeoProps) => {
  const router = useRouter();

  useEffect(() => {
    if (preview) return;
    if (
      config?.integrations?.gtmid &&
      process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'
    )
      TagManager.initialize({ gtmId: config?.integrations?.gtmid });
  }, [config?.integrations?.gtmid, preview]);

  if (!config?.seo || !page || !sitemap) return null;

  let pagePath = router.asPath;
  if (pagePath === '/index') pagePath = '/';

  const baseUrl = `https://${config?.general?.domain}`;
  const seoTitle = `${preview ? 'Preview mode 👀 - ' : ''}${
    page.seo?.title || page.title
  }`;
  const seoDescription =
    page.description || page.seo?.description || config?.seo?.description;
  const seoCanonical = `${baseUrl}${pagePath}`;

  const hero: { image?: ImageType } =
    page?.hero ||
    page?.modules?.find(
      ({ _type }: { _type: ModuleSchemaName }) => _type === 'module.textimage',
    );

  const seoImageObj =
    page.image || page.seo?.image || hero?.image || config?.seo?.image;

  const seoImage = {
    url: getResponsiveImageUrl({
      src: seoImageObj?.src,
      width: 1200,
      height: 630,
      crop: seoImageObj?.crop,
      hotspot: seoImageObj?.hotspot,
    }),
    width: 1200,
    height: 630,
    alt: seoImageObj?.alt || '',
  };

  const brandJsonLd = {
    '@context': 'https://schema.org',
    '@type': 'Brand',
    name: config?.general?.name,
    description: config?.seo?.description,
    logo: `${baseUrl}/logo.svg`,
    URL: baseUrl,
    sameAs: config?.social?.socials,
  };

  const breadCrumb = getBreadCrumbForPath(pagePath, sitemap);

  return (
    <>
      <NextSeo
        title={seoTitle}
        titleTemplate={`%s - ${config?.general?.name}`}
        description={seoDescription}
        canonical={seoCanonical}
        openGraph={{
          type: 'website',
          url: seoCanonical,
          title: seoTitle,
          description: seoDescription,
          images: [seoImage],
          site_name: config?.general?.name,
        }}
        twitter={{
          handle: config?.social?.twitter?.handle,
          site: config?.social?.twitter?.url,
          cardType: 'summary_large_image',
        }}
      />

      <LogoJsonLd logo={`${baseUrl}/logo.svg`} url={baseUrl} />

      {Boolean(breadCrumb?.length) && (
        <BreadcrumbJsonLd
          itemListElements={breadCrumb.map(({ title, path }, index) => ({
            position: index + 1,
            name: title,
            item: `${baseUrl}${path}`,
          }))}
        />
      )}

      <NextHead>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(brandJsonLd, null, 2) }}
        />
      </NextHead>

      <Script strategy="worker" id="intercom">
        {`
        var APP_ID = "${config?.integrations?.intercomid}";

        window.intercomSettings = {
            app_id: APP_ID
          };
        (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/' + APP_ID;var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
        `}
      </Script>
    </>
  );
};
