import cx from 'classnames';
import { motion } from 'framer-motion';
import React from 'react';

import { textClasses } from '../../colors';
import { ImageType } from '../../types';
import { AnimateInView } from '../animation/AnimateInView';
import { Image } from '../images/Image';
import { BackgroundProps } from '../module/Background';
import { ColorType } from '../module/BackgroundOptions';

export type TestimonialProps = {
  name?: string;
  label?: string;
  image: ImageType;
  content?: React.ReactElement | React.ReactNode;
  animationDirection?: number;
  mode?: 'compact' | 'regular';
} & Partial<BackgroundProps>;

const animationVariants = {
  enter: (animationDirection: number) => {
    return {
      x: 30 * animationDirection,
      opacity: 0,
    };
  },
  center: {
    x: 0,
    opacity: 1,
  },
  exit: (animationDirection: number) => {
    return {
      x: 120 * animationDirection * -1,
      opacity: 0,
    };
  },
};

export const Testimonial = ({
  name,
  content,
  image,
  label,
  theme = { background: 'white' },
  animationDirection,
  mode = 'regular',
}: TestimonialProps) => (
  <div
    key={name}
    className={cx(
      'overflow-x-hidden flex flex-col max-w-quote',
      textClasses[theme?.background],
      {
        ['mx-auto text-center font-light items-center px-4']: mode === 'regular',
        ['p-4 md:p-6 lg:p-8']: mode === 'compact',
      },
    )}
  >
    <blockquote>
      {content && (
        <motion.div
          variants={animationVariants}
          custom={animationDirection}
          transition={{ delay: 0, opacity: { duration: 0.5 } }}
          className={cx('quote-body', textClasses[theme?.quote], {
            ['mb-12 text-lg md:text-xl']: mode === 'regular',
            ['mb-12 text-lg']: mode === 'compact',
          })}
        >
          {content}
        </motion.div>
      )}
      <div className={cx('flex items-center', { ['flex-col']: mode === 'regular' })}>
        {image?.src && (
          <AnimateInView>
            <motion.div
              variants={animationVariants}
              custom={animationDirection}
              transition={{ delay: 0.05 }}
              className={cx('aspect-video', {
                ['mx-auto h-24 mb-4 w-auto']: mode === 'regular',
                ['mr-4 h-16 w-auto']: mode === 'compact',
              })}
            >
              <Image {...image} alt="Testimonial image" layout="responsive" />
            </motion.div>
          </AnimateInView>
        )}
        {(name || label) && (
          <figcaption
            className={cx({
              ['text-center mt-4']: mode === 'regular',
              ['leading-none']: mode === 'compact',
            })}
          >
            {name && (
              <motion.span
                variants={animationVariants}
                custom={animationDirection}
                transition={{ delay: 0.1 }}
                className={cx('block text-md font-bold', textClasses[theme?.name])}
              >
                {name}
              </motion.span>
            )}{' '}
            {label && (
              <motion.cite
                variants={animationVariants}
                custom={animationDirection}
                transition={{ delay: 0.15 }}
                className={cx('block text-sm not-italic', textClasses[theme?.label])}
              >
                {label}
              </motion.cite>
            )}
          </figcaption>
        )}
      </div>
    </blockquote>
  </div>
);

export const TestimonialMemo = React.memo(Testimonial);
