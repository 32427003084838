import { motion } from 'framer-motion';
import Link from 'next/link';
import React, { useState } from 'react';

import { Button } from '../components/buttons/Button';
import { StaticFormState } from '../layout/ModuleBuilder/StaticFormBuilder';
import { StaticFormOptionsType } from '../types';

export type ContactValues = {
  fullname?: string;
  email?: string;
  message?: string;
  jobtitle?: string;
  company?: string;
  country?: string;
  budget?: string;
};

export type ContactProps = {
  success?: React.ReactNode | React.ReactElement;
  error?: React.ReactNode | React.ReactElement;
  state?: StaticFormState;
  onSubmit?: (values: ContactValues) => void;
  result?: string;
  options?: StaticFormOptionsType;
};

export const Contact = ({
  success,
  error,
  onSubmit,
  result,
  state,
  options,
}: ContactProps) => {
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        if (state === 'loading') return;
        const form: HTMLFormElement = e.currentTarget;
        onSubmit({
          fullname: form.fullname.value,
          email: form.email.value,
          message: form.message.value,
          jobtitle: form.jobtitle.value,
          company: form.company.value,
          country: form.country.value,
          budget: form.budget.value,
        });
      }}
    >
      {state === 'success' && (
        <motion.div
          className="prose bg-white border border-gray-200 rounded-md p-5"
          animate={{ opacity: 1 }}
          initial={{ opacity: 0 }}
          transition={{ ease: 'circOut', delay: 0.25 }}
        >
          {success}
        </motion.div>
      )}

      {state === 'error' && (
        <div className="prose prose-sm mb-4 border border-red-100 bg-red-50 bg-opacity-50 py-1 px-2">
          {result || error}
        </div>
      )}

      {state !== 'success' && (
        <motion.div
          animate={{ opacity: 1 }}
          initial={{ opacity: 0 }}
          exit={{ opacity: 0 }}
          transition={{ ease: 'circOut' }}
        >
          <div className="mb-5 text-left">
            <label>
              Name <span className="text-red-400">*</span>
              <input
                required
                type="text"
                name="fullname"
                className="mt-1 border bg-white border-gray-300 placeholder-navy md:text-base w-full"
              />
            </label>
          </div>

          <div className="mb-5 text-left">
            <label>
              Work email <span className="text-red-400">*</span>
              <input
                required
                type="email"
                name="email"
                className="mt-1 border bg-white border-gray-300 placeholder-navy md:text-base w-full"
              />
            </label>
          </div>

          <div className="mb-5 text-left">
            <label>
              Job Title <span className="text-red-400">*</span>
              <input
                required
                type="text"
                name="jobtitle"
                className="mt-1 border bg-white border-gray-300 placeholder-navy md:text-base w-full"
              />
            </label>
          </div>

          <div className="mb-5 text-left">
            <label>
              Company name <span className="text-red-400">*</span>
              <input
                required
                type="text"
                name="company"
                className="mt-1 border bg-white border-gray-300 placeholder-navy md:text-base w-full"
              />
            </label>
          </div>

          <div className="mb-5 text-left">
            <label>
              Country <span className="text-red-400">*</span>
              <input
                required
                type="text"
                name="country"
                className="mt-1 border bg-white border-gray-300 placeholder-navy md:text-base w-full"
              />
            </label>
          </div>

          <div className="mb-5 text-left">
            <label>
              Budget
              <input
                type="text"
                name="budget"
                className="mt-1 border bg-white border-gray-300 placeholder-navy md:text-base w-full"
              />
            </label>
          </div>

          <div className="mb-5 text-left">
            <label>
              Message <span className="text-red-400">*</span>
              <textarea
                required
                name="message"
                className="mt-1 resize-y h-32 border bg-white border-gray-300 placeholder-navy md:text-base w-full"
              />
            </label>
          </div>

          <div className="mb-5 text-left">
            <Button
              label={'Send'}
              size="md"
              round
              as="submit"
              theme={{ background: 'purple-500', text: 'white' }}
              disabled={state === 'loading'}
              loading={state === 'loading'}
            />
          </div>
        </motion.div>
      )}
    </form>
  );
};

export const ContactMemo = React.memo(Contact);
