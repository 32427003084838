import cx from 'classnames';
import React from 'react';

import { Slider } from '../../components/Slider/Slider';
import { ButtonProps } from '../../components/buttons/Button';
import { ButtonGroup } from '../../components/buttons/ButtonGroup';
import { AlignType as alignButtonsType } from '../../components/buttons/ButtonGroupOptions';
import { DecorationProps } from '../../components/module/Decoration';
import {
  AlignType,
  Text,
  SizeType as TextSizeType,
} from '../../components/module/Text';
import { Title, SizeType as TitleSizeType } from '../../components/module/Title';
import { Wrapper } from '../../components/module/Wrapper';
import { BREAKPOINTS, useBreakpoint } from '../../hooks/useBreakpoint';
import { ColorType, HeadingSizeType } from '../../types';
import { ThemeType } from '../../types';
import { ColumnType, GapType } from './CardGridOptions';
import { IconCard, IconCardProps } from './IconCard';

export type CardGridCardProps = {
  _key?: string;
  rounded?: boolean;
  theme?: ThemeType;
};

export type CardGridProps = {
  decorations?: DecorationProps[];
  title?: string;
  items?: IconCardProps[];
  buttons?: ButtonProps[];
  slider?: boolean;
  intro?: string;
  headingSize?: HeadingSizeType;
  theme?: {
    title?: ColorType;
    intro?: ColorType;
    background?: ColorType;
    cardTitle?: ColorType;
    cardText?: ColorType;
    cardBackground?: ColorType;
    alignText?: AlignType;
    alignButtons?: AlignType;
    introSize?: TextSizeType;
    titleSize?: TitleSizeType;
    cardsRounded?: boolean;
    columns?: ColumnType;
    gap?: GapType;
    cardClickable?: boolean;
  };
};

const gridClasses: Record<ColumnType, string> = {
  1: 'grid-cols-1',
  2: 'sm:grid-cols-2',
  3: 'sm:grid-cols-2 lg:grid-cols-3',
  4: 'sm:grid-cols-2 lg:grid-cols-4',
  5: 'sm:grid-cols-2 lg:grid-cols-5',
};

const gapClasses: Record<GapType, string> = {
  none: 'gap-0',
  xs: 'gap-1',
  sm: 'gap-2',
  md: 'gap-4',
  lg: 'gap-7',
  xl: 'gap-10',
};

const gapSizes: Record<GapType, number> = {
  none: 0,
  xs: 1 * 4,
  sm: 2 * 4,
  md: 4 * 4,
  lg: 7 * 4,
  xl: 10 * 4,
};

export const CardGrid = ({
  decorations,
  title,
  intro,
  items,
  buttons,
  slider = false,
  theme,
  headingSize,
}: CardGridProps) => {
  const { screenWidth } = useBreakpoint();
  let slideColumns = 1;
  if (screenWidth > BREAKPOINTS.xs) slideColumns = 1.5;
  if (screenWidth > BREAKPOINTS.sm) slideColumns = 2;
  if (screenWidth > BREAKPOINTS.lg) slideColumns = theme?.columns || 3;

  items = items?.map((x) => ({
    ...x,
    ['theme']: {
      title: x.theme?.title || theme?.cardTitle,
      text: x.theme?.text || theme?.cardText,
      background: x.theme?.background || theme?.cardBackground,
    },
    ['rounded']: theme?.cardsRounded,
  }));

  return (
    <Wrapper id={title} theme={theme as ThemeType} decorations={decorations}>
      <div
        className={cx('flex flex-col gap-8', {
          ['text-left']: theme?.alignText === 'left',
          ['text-center']: theme?.alignText === 'center',
          ['text-right']: theme?.alignText === 'right',
        })}
      >
        {Boolean(title?.trim().length) && (
          <Title
            as={headingSize ?? 'h2'}
            size={theme?.titleSize}
            color={theme?.title}
          >
            {title}
          </Title>
        )}

        {intro && (
          <Text
            font="heading"
            size={theme?.introSize}
            color={theme?.intro}
            align={theme?.alignText}
            as="div"
          >
            {intro}
          </Text>
        )}

        {Boolean(items?.length) && (
          <div className="text-left">
            {slider ? (
              <Slider
                gap={gapSizes[theme?.gap || 'xs']}
                columns={slideColumns}
                theme={theme}
                slides={items?.map((item) => {
                  if (item?.type === 'card.icon')
                    return (
                      <div className="h-full" key={item._key}>
                        <IconCard
                          {...item}
                          key={item._key}
                          clickable={theme?.cardClickable}
                        />
                      </div>
                    );
                })}
              />
            ) : (
              <ul
                className={cx(
                  'grid',
                  gridClasses[theme?.columns || 2],
                  gapClasses[theme?.gap || 'xs'],
                )}
              >
                {items?.map((item) => (
                  <li key={item._key} className="h-full">
                    {item?.type === 'card.icon' && (
                      <IconCard {...item} clickable={theme?.cardClickable} />
                    )}
                  </li>
                ))}
              </ul>
            )}
          </div>
        )}

        {buttons && (
          <div
            className={cx('flex mt-10', {
              ['justify-start']: theme?.alignButtons === 'left',
              ['justify-center']: theme?.alignButtons === 'center',
              ['justify-end']: theme?.alignButtons === 'right',
            })}
          >
            <ButtonGroup
              items={buttons}
              stretch={false}
              direction="horizontal"
              align={
                theme?.alignButtons === 'center'
                  ? 'center'
                  : theme?.alignButtons === 'right'
                  ? 'right'
                  : 'left'
              }
            />
          </div>
        )}
      </div>
    </Wrapper>
  );
};

export const CardGridMemo = React.memo(CardGrid);
