import React from 'react';

import { Breadcrumb as BreadcrumbComponent } from '../../components/Breadcrumb/Breadcrumb';
import { Wrapper } from '../../components/module/Wrapper';
import { SitemapType } from '../../queries/sitemap';
import { ThemeType } from '../../types';

export type BreadcrumbProps = {
  theme?: ThemeType;
  path?: SitemapType;
};

export const Breadcrumb = ({ theme, path }: BreadcrumbProps) => {
  return (
    <Wrapper space="none" theme={theme}>
      <BreadcrumbComponent path={path} />
    </Wrapper>
  );
};

export const BreadcrumbMemo = React.memo(Breadcrumb);
