import cx from 'classnames';
import React from 'react';

export type WidthProps = {
  width?: 'full' | 'inner';
  children: React.ReactElement | React.ReactNode;
  className?: string;
};

export const Width = ({ children, width, className }: WidthProps) => {
  return (
    <div
      className={cx('mx-auto px-4', className, {
        ['max-w-xl']: width !== 'full',
      })}
    >
      {children}
    </div>
  );
};

export const WidthMemo = React.memo(Width);
