import cx from 'classnames';
import Link from 'next/link';
import React, { useState, useEffect } from 'react';

import { textClasses } from '../../colors';
import { AnimateInView } from '../../components/animation/AnimateInView';
import { DateDisplay } from '../../components/date/DateDisplay';
import { Image } from '../../components/images/Image';
import { BackgroundProps } from '../../components/module/Background';
import { Text } from '../../components/module/Text';
import { Title } from '../../components/module/Title';
import { Wrapper } from '../../components/module/Wrapper';
import { ImageType } from '../../types';

export type BlogFeedItem = {
  label?: string;
  image?: ImageType;
  title?: string;
  intro?: string;
  date?: string;
  href?: string;
  categories?: CategoryProps[];
};

export type BlogFeedProps = {
  items?: BlogFeedItem[];
  categories?: CategoryProps[];
  displayFilter?: boolean;
  title?: string;
} & Partial<BackgroundProps>;

export const BlogFeed = ({
  theme,
  items = [],
  categories,
  displayFilter = false,
  title,
}: BlogFeedProps) => {
  if (!Boolean(items?.length)) return null;

  if (items.length < 1) return null;

  const [category, setCategory] = useState<CategoryProps | null>(null);
  const [filteredItems, setFilteredItems] = useState<BlogFeedItem[]>(items);

  useEffect(() => {
    if (category) {
      setFilteredItems(
        items.filter((s) => s.categories?.find((s) => s._id == category._id)),
      );
    } else {
      setFilteredItems(items);
    }
  }, [category]);

  return (
    <Wrapper theme={theme}>
      {Boolean(title) && <Title>{title}</Title>}
      <div className="flex justify-between flex-col md:flex-row md:items-center">
        <Categories {...{ categories }} />
        {displayFilter ? (
          <FilterSelect
            onChange={(category: string) =>
              setCategory(categories.find((s) => s.title == category))
            }
            categories={categories}
          />
        ) : null}
      </div>
      <ul className="grid grid-cols-1 divide-y divide-gray-100 pb-24">
        {filteredItems.map(({ title, intro, image, date, href }) => (
          <li key={title}>
            <Link href={href || ''}>
              <a className="group grid grid-cols-1 sm:grid-cols-2 items-center sm:items-start lg:items-center gap-10 md:gap-18 lg:gap-24 py-4">
                <div className="order-2">
                  <DateDisplay
                    datetime={date}
                    format={{ year: 'numeric', month: 'long', day: 'numeric' }}
                  />

                  <div className="mb-4 sm:mb-5">
                    <Title
                      as="h2"
                      className={cx(
                        'group-hover:underline',
                        textClasses[theme?.title],
                      )}
                    >
                      {title}
                    </Title>
                  </div>

                  <div className="mb-4 sm:mb-6">
                    <Text theme={theme} align="left" as="div" color={theme?.text}>
                      {intro}
                    </Text>
                  </div>
                </div>

                <figure>
                  <AnimateInView>
                    <div className="relative flex md:justify-center aspect-[4/3] border-[12px] border-white outline outline-1 outline-[rgba(0,0,0,.1)] rounded-xl overflow-hidden">
                      <Image
                        alt="Blog post thumbnail"
                        {...image}
                        layout="fill"
                        className="rounded-[8px]"
                      />
                    </div>
                  </AnimateInView>
                </figure>
              </a>
            </Link>
          </li>
        ))}
      </ul>
    </Wrapper>
  );
};

type FilterSelectProps = {
  onChange: (value: string) => void;
  categories: CategoryProps[];
};

export const FilterSelect = ({ onChange, categories }: FilterSelectProps) => {
  if (!categories) {
    return null;
  }

  return (
    <select
      className="border border-gray-300 shadow-sm rounded-lg"
      onChange={(e) => onChange(e.target.value)}
    >
      <option value={null}>{'All categories'}</option>;
      {categories.map((category: CategoryProps) => {
        return (
          <option key={category._id} value={category.title}>
            {category.title}
          </option>
        );
      })}
    </select>
  );
};

type CategoriesProps = {
  categories: CategoryProps[];
};

export const Categories = ({ categories }: CategoriesProps) => {
  if (!categories) {
    return null;
  }

  return (
    <div className="">
      <div className="my-3 flex flex-wrap">
        {categories.map((category: CategoryProps) => {
          return (
            <div className="flex mr-3 mt-3">
              <Category key={category._id} {...category} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export type CategoryProps = {
  _id: string;
  title: string;
  href: string;
};

const Category = ({ title, href }: CategoryProps) => {
  if (!href || !title) {
    return null;
  }

  return (
    <div className="flex">
      <div className="group px-3 py-2 shadow-sm hover:bg-gray-50 cursor-pointer border-gray-300 border rounded-full">
        <Link {...{ href }}>
          <p className="group-hover:underline underline-offset-4">{title}</p>
        </Link>
      </div>
    </div>
  );
};

export const BlogFeedMemo = React.memo(BlogFeed);
